import React from "react";
import { Button, Tooltip } from "@centrate-io/barn";
import PayPathSmall from "_assets/logos/icon.svg";
import { Toggle, Tag, TaskBadge } from "_styleguide";
import {
  // IconSettings,
  IconInfoCircle,
  IconPlugConnected,
} from "@tabler/icons-react";
import "./IntegrationCard.scss";

function IntegrationCard({
  info,
  data,
  settings,
  toggleActive,
  removeIntegration,
  reload,
  user,
}) {
  const settingsTooltip = () => {
    let tt = (
      <div>
        {Object.keys(settings?.info || {}).map((key) => (
          <div key={`ic-${key}`}>
            <b>{key}</b>: {settings?.info?.[key]}
          </div>
        ))}
      </div>
    );
    if (data && !data.active) {
      tt = "Toggle active to use";
    } else if (!data || data?.connected !== true) {
      tt = "Connect integration to use";
    }
    return (
      <Tooltip title={tt}>
        <Button className="boxed" type="secondary">
          <IconInfoCircle />
        </Button>
      </Tooltip>
    );
  };

  const isDisabled = user?.parent_company && info.company_only ? true : false;
  const isLive = data?.connected && data?.active;

  return (
    <div className={`int-card ${isLive ? "is-live" : ""}`}>
      {data?.connected ? (
        <Tooltip
          title={
            isDisabled
              ? `Reach out to ${user?.parent_company?.name} to Enable/Disable`
              : "Toggle Active/Inactive"
          }
        >
          <Toggle
            size="small"
            disabled={isDisabled}
            onClick={() => toggleActive(data.id, data.active)}
            checked={data?.active}
          />
        </Tooltip>
      ) : null}

      <div className="int-info">
        <div className="int-brand">
          <img src={info.logo} alt={info.name} />
          <h3>{info.name}</h3>
        </div>{" "}
        <p>{info.description}</p>
        <div className="int-tags">
          <TaskBadge type={info.category} full="true" />
          {info.company_only ? (
            <Tooltip title="The company sets up this integration and all users can use it">
              <Tag type="super">Company</Tag>
            </Tooltip>
          ) : null}
          {info.contact_only ? (
            <Tooltip title="Reach out to PayPath via email to setup this integration">
              <Tag>
                <img src={PayPathSmall} alt="PP" />
              </Tag>
            </Tooltip>
          ) : null}
        </div>
      </div>
      <div className="int-actions">
        {settings?.type === "tooltip" || !data || data?.connected !== true
          ? settingsTooltip()
          : null}
        {isDisabled ? null : (
          <>
            {data?.connected && !info.contact_only ? (
              <Button
                className="boxed"
                type="danger"
                onClick={() => removeIntegration(data.id)}
              >
                <IconPlugConnected /> Disconnect
              </Button>
            ) : null}
            {!data?.connected && !info.contact_only ? (
              <info.Connect reload={reload} user={user} data={data} />
            ) : null}
          </>
        )}
      </div>
    </div>
  );
}

export default IntegrationCard;

import { gql } from "@apollo/client";

export const getCompanyInvite = gql`
  query getCompanyInvite($company_id: ID!) {
    getCompanyInvite(company_id: $company_id) {
      name
      hasPassword
      company
      email
      phone
    }
  }
`;

export const getCompany = gql`
  query {
    getCompany {
      name
      logo
      address
      address_line_two
      city
      state
      zip
    }
  }
`;

import { Select } from "_styleguide";
import { TAG_MAP } from "_constants/mapping";
import "./TemplateMapping.scss";

function TemplateMapping(props) {
  const { form, updateForm } = props;

  const changeRow = (index, value) => {
    let newMapping = [...form.variables];
    newMapping[index].mapping = value;
    updateForm("variables", newMapping);
  };

  return (
    <div className="ct-mapping">
      <div className="wb-headers">
        <div>Variable Name</div>
        <div className="ct-select">Replace with</div>
        <div>Example</div>
      </div>
      <div className="wb-work">
        {form.variables?.map((m, i) => (
          <div key={`wb-map-${i}`} className="wb-map">
            <div>
              <b>{`{{${m.variable}}}`}</b>
            </div>
            <div className="ct-select">
              <Select
                // showSearch
                // allowClear={true}
                value={m.mapping}
                onChange={(val) => changeRow(i, val)}
                size="medium"
                placeholder="Ignore"
                popupClassName="pp-select-dropdown ppsd-task"
              >
                {Object.keys(TAG_MAP)?.map((u, j) => {
                  const tagData = TAG_MAP[u];
                  return (
                    <Select.Option key={`ct-${u}`} value={u}>
                      {tagData.label}
                    </Select.Option>
                  );
                })}
              </Select>
            </div>
            <div>
              <i>{TAG_MAP[m.mapping]?.example || "n/a"}</i>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default TemplateMapping;

import React, { useState } from "react";
import { Search } from "_styleguide";
import { Collapse } from "antd";
import { Button } from "@centrate-io/barn";
import { IconPlus, IconEdit } from "@tabler/icons-react";
import "./TaskTemplates.scss";

const TemplateItem = (props) => (
  <div className="template-item" onClick={() => props.onClick(props.t)}>
    {props.name}
    <IconEdit />
  </div>
);

function TaskTemplates(props) {
  const [search, setSearch] = useState("");

  const { tasks } = props;

  const actualTasks = tasks.filter((t) => {
    if (search?.length <= 0) return true;
    return t.title?.toLowerCase().includes(search?.toLowerCase());
  });

  const callTasks = actualTasks.filter((t) => t.type === "call");
  const generalTasks = actualTasks.filter((t) => t.type === "general");
  const emailTasks = actualTasks.filter((t) => t.type === "email");
  const textTasks = actualTasks.filter((t) => t.type === "text");

  let activeStart = [];
  if (callTasks?.length > 0) activeStart.push("call");
  if (generalTasks?.length > 0) activeStart.push("general");
  if (emailTasks?.length > 0) activeStart.push("email");
  if (textTasks?.length > 0) activeStart.push("text");

  const editTemplate = (template) => {
    props.showNewTask({
      id: template.id,
      title: template.title,
      type: template.type,
      instructions: template.instructions,
      subject: template.subject,
      variables: template.variables,
      is_html: template.is_html,
    });
  };

  const isSearching = search?.length > 0;

  return (
    <div className="task-templates">
      <div className="task-title">
        <h3>Templates</h3>
        <Button
          type="blanco"
          size="tiny"
          className="boxed"
          onClick={() => props.showNewTask(true)}
        >
          <IconPlus /> New
        </Button>
      </div>
      <Search
        placeholder={`Search templates...`}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        style={{ width: "100%" }}
        allowClear={true}
      />
      <Collapse
        defaultActiveKey={activeStart}
        items={[
          {
            key: "call",
            label: `Call (${callTasks?.length})`,
            children:
              callTasks?.length === 0 ? (
                <div className="tt-empty">
                  No call templates {isSearching ? "found" : ""}
                </div>
              ) : (
                callTasks.map((t) => (
                  <TemplateItem
                    key={t.id}
                    t={t}
                    name={t.title}
                    id={t.id}
                    onClick={editTemplate}
                  />
                ))
              ),
          },
          {
            key: "general",
            label: `General (${generalTasks?.length})`,
            isActive: true,
            children:
              generalTasks?.length === 0 ? (
                <div className="tt-empty">
                  No general templates {isSearching ? "found" : ""}
                </div>
              ) : (
                generalTasks.map((t) => (
                  <TemplateItem
                    key={t.id}
                    t={t}
                    name={t.title}
                    id={t.id}
                    onClick={editTemplate}
                  />
                ))
              ),
          },
          {
            key: "email",
            label: `Email (${emailTasks?.length})`,
            children:
              emailTasks?.length === 0 ? (
                <div className="tt-empty">
                  No email templates {isSearching ? "found" : ""}
                </div>
              ) : (
                emailTasks.map((t) => (
                  <TemplateItem
                    key={t.id}
                    t={t}
                    name={t.title}
                    id={t.id}
                    onClick={editTemplate}
                  />
                ))
              ),
          },
          {
            key: "text",
            label: `Text Message (${textTasks?.length})`,
            children:
              textTasks?.length === 0 ? (
                <div className="tt-empty">
                  No text templates {isSearching ? "found" : ""}
                </div>
              ) : (
                textTasks.map((t) => (
                  <TemplateItem
                    key={t.id}
                    t={t}
                    name={t.title}
                    id={t.id}
                    onClick={editTemplate}
                  />
                ))
              ),
          },
        ]}
      />
    </div>
  );
}

export default TaskTemplates;

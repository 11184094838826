import { Text } from "@centrate-io/barn";
import {
  IconFileTypePdf,
  IconSignature,
  IconDevicesQuestion,
  IconScale,
  IconUser,
  IconDownload,
  IconCalendar,
  IconCircleX,
} from "@tabler/icons-react";
import { AvatarName, TableActions } from "_styleguide";
import { formatBytes, viewDocument } from "_assets/js/helpers";
import moment from "moment";

export const getColumns = (removeDocument, readOnly) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Name
        </div>
      ),
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <div>
          <Text className="doc-name">
            <IconFileTypePdf />
            <b>{name}</b>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconDevicesQuestion />
          Type
        </div>
      ),
      dataIndex: "fileType",
      key: "fileType",
      sorter: true,
      width: 120,
      render: (fileType) => <Text>{fileType}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Size
        </div>
      ),
      dataIndex: "size",
      key: "size",
      sorter: true,
      width: 120,
      render: (amount) => <Text>{formatBytes(amount)}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Added by
        </div>
      ),
      dataIndex: "user",
      key: "user",
      sorter: true,
      render: (user, data) =>
        !data?.user ? (
          <div className="doc-user">
            <div className="doc-user-name">
              <i>Customer</i>
            </div>
          </div>
        ) : (
          <div className="doc-user">
            <AvatarName
              size="small"
              tooltip={`${data?.user?.name} (${data?.company?.name})`}
            >
              {data?.user?.name?.[0]}
            </AvatarName>
            <div className="doc-user-name">
              <b>{data?.user?.name}</b>
            </div>
          </div>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Uploaded
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 150,
      render: (createdAt) => (
        <Text>
          {createdAt ? (
            moment(parseInt(createdAt)).format("M/D/YYYY")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
    {
      title: "",
      dataIndex: "url",
      key: "url",
      sorter: false,
      width: 60,
      fixed: "right",
      render: (url, data) => {
        const items = [
          {
            label: (
              <span>
                <IconDownload /> Download document
              </span>
            ),
            onClick: () => viewDocument(url),
          },
        ];
        if (!readOnly) {
          items.push({
            label: (
              <span className="bad">
                <IconCircleX /> Delete document
              </span>
            ),
            onClick: () => removeDocument(data.id),
          });
        }
        return <TableActions items={items} />;
      },
    },
  ];
};

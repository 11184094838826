import React, { useState, useEffect } from "react";
import { fetchTemplates, replaceData } from "../../../../_helpers/templates";
import { useApolloClient } from "@apollo/client";
import { useBar } from "_contexts/useBar";
import { Select, TaskBadge, Quill } from "_styleguide";
import "./Script.scss";

function Script(props) {
  const client = useApolloClient();
  const { activeData } = useBar();
  const [data, setData] = useState(true);
  const [template, setTemplate] = useState(
    activeData?.task?.template_id || undefined,
  );
  const [instructions, setInstructions] = useState(null);

  const changeTemplate = (templateId) => {
    const templateData = data?.templates?.find((t) => t.id === templateId);
    setTemplate(templateId);
    if (!templateData) {
      setInstructions(null);
      return;
    }
    const newInstructions = replaceData(
      templateData,
      data.customer,
      props.user,
    );
    setInstructions(newInstructions || "No script found");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchTemplates(
          client,
          "call",
          activeData.customer?.id,
        );
        setData(data); // Handle the fetched data
        if (activeData?.task?.template_id) {
          const templateData = data?.templates?.find(
            (t) => t.id === activeData?.task?.template_id,
          );
          if (!templateData?.instructions) {
            setTemplate(undefined);
            return;
          }
          const newInstructions = replaceData(
            templateData,
            data.customer,
            props.user,
          );
          setInstructions(newInstructions || "No script found");
        }
      } catch (error) {
        console.error("Error fetching templates:", error);
      }
    };
    if (activeData.customer?.id) fetchData();
  }, []);

  const templateOptions = data?.templates || [];

  return (
    <div className={`pp-script ${props.visible ? "visible" : ""}`}>
      <Select
        value={template}
        onChange={changeTemplate}
        size="medium"
        popupClassName="pp-select-dropdown ppsd-task"
        placeholder="---"
      >
        <Select.Option value={null}>---</Select.Option>
        {templateOptions.map((t) => {
          return (
            <Select.Option key={t.id} value={t.id}>
              <TaskBadge type="call" />
              {t.title}
            </Select.Option>
          );
        })}
      </Select>
      <Quill
        value={instructions}
        readOnly={true}
        modules={{ toolbar: false }}
        placeholder="Select a call template script above to get started..."
      />
    </div>
  );
}

export default Script;

import React from "react";
import { Radio as AntdRadio } from "antd";
import "./RadioGroup.scss";

const RadioGroup = (incomingProps) => {
  const props = { ...incomingProps };
  props.className = "pp-radio-group " + (props.className || "");

  return <AntdRadio.Group {...props} />;
};

RadioGroup.Button = AntdRadio.Button;
export default RadioGroup;

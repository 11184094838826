import React, { Component } from "react";
import "./CompanyAvatar.scss";

class CompanyAvatar extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-company-avatar " + (props.className || "");

    if (props.size) {
      props.className += " pp-company-avatar-" + props.size;
    }
    let logo = props.user?.company?.logo || null;
    let name = props.user?.company?.name || "?";

    return (
      <div {...props}>
        {logo ? (
          <img src={logo} alt="Logo" />
        ) : (
          <span>{name[0]?.toUpperCase() || "?"}</span>
        )}
      </div>
    );
  }
}

export default CompanyAvatar;

import {
  IconPhone,
  IconMail,
  IconMessage,
  IconMailbox,
  IconSubtask,
  // IconNotification,
} from "@tabler/icons-react";
import { UserAvatar } from "_styleguide";
import { Tooltip } from "@centrate-io/barn";
import { useLocation } from "react-router-dom";
import { useBar } from "_contexts/useBar";
import BarIcon from "./_components/BarIcon/BarIcon";
import Dialer from "./_components/Dialer/Dialer";
import Texting from "./_components/Texting/Texting";
import Email from "./_components/Email/Email";
import Mail from "./_components/Mail/Mail";
import { useIntegrations } from "_contexts/useIntegrations";
import "./Bar.scss";

function Bar(props) {
  let location = useLocation();
  const { integrations } = useIntegrations();
  const {
    activeData,
    customer,
    setupCall,
    setupEmail,
    setupText,
    setupMail,
    toggleMinimize,
    callDuration,
  } = useBar();
  const customerData = location?.pathname?.includes("/customer/") && customer;

  const iconProps = {
    data: activeData,
    actions: {
      call: () => setupCall(customerData, null, true),
      text: () => setupText(customerData, null, true),
      email: () => setupEmail(customerData, null, true),
      mail: () => setupMail(customerData, null, true),
    },
    minimize: toggleMinimize,
  };

  return (
    <>
      {activeData?.type === "call" ? (
        <Dialer {...props} actions={iconProps.actions} />
      ) : null}
      {activeData?.type === "text" ? (
        <Texting {...props} actions={iconProps.actions} />
      ) : null}
      {activeData?.type === "email" ? (
        <Email {...props} actions={iconProps.actions} />
      ) : null}
      {activeData?.type === "mail" ? (
        <Mail {...props} actions={iconProps.actions} />
      ) : null}
      <div className="pp-bar">
        {customerData ? (
          <>
            <Tooltip
              title={`${customerData.first_name} ${customerData.last_name}`}
            >
              <UserAvatar name={`${customerData.first_name}`} size="small" />
            </Tooltip>
            <div className="bar-divider" />
          </>
        ) : null}
        <BarIcon
          icon={<IconPhone />}
          type="outreach"
          name="call"
          callDuration={callDuration}
          {...iconProps}
        />
        <BarIcon
          icon={<IconMail />}
          type="outreach"
          name="email"
          {...iconProps}
        />
        <BarIcon
          icon={<IconMessage />}
          type="outreach"
          name="text"
          {...iconProps}
        />
        {integrations.mail ? (
          <BarIcon
            icon={<IconMailbox />}
            type="outreach"
            name="mail"
            {...iconProps}
          />
        ) : null}
        <div className="bar-divider" />
        <BarIcon
          onClick={props.showMyTasks}
          icon={<IconSubtask />}
          type="shortcut"
          name="tasks"
          {...iconProps}
        />
        {/*<BarIcon icon={<IconNotification />} name="notifications" />*/}
      </div>
    </>
  );
}

export default Bar;

import React, { createContext, useContext, useEffect, useState } from "react";
import { getIntegrations } from "App/Admin/_graphql/queries/integration";
const IntegrationsContext = createContext();

// Create a provider component
export const IntegrationsProvider = ({ children, client }) => {
  const [integrations, setIntegrations] = useState([]);

  // Load Payments
  const fetchIntegrations = async () => {
    const queryData = await client.query({
      query: getIntegrations,
    });
    organize(queryData?.data?.getIntegrations || []);
  };

  const organize = (ints) => {
    const active = {
      email: null,
      text: null,
      call: null,
      mail: null,
    };
    ints.forEach((i) => {
      if (
        i.category === "email" &&
        i.type === "mailgun" &&
        i.connected &&
        i.active
      ) {
        active[i.category] = i;
      } else if (!active[i.category] && i.connected && i.active)
        active[i.category] = i;
    });
    setIntegrations(active);
  };

  useEffect(() => {
    fetchIntegrations();
  }, []);

  return (
    <IntegrationsContext.Provider
      value={{
        integrations,
        reloadIntegrations: fetchIntegrations,
      }}
    >
      {children}
    </IntegrationsContext.Provider>
  );
};

// Custom hook to use the context
export const useIntegrations = () => {
  return useContext(IntegrationsContext);
};

export const gatherTo = (activeData) => {
  let toList = [];
  if (activeData?.mode === "reply-email" && activeData?.reply?.to) {
    toList = [...activeData?.reply?.to];
  } else if (activeData?.customer?.email) {
    toList = [activeData?.customer?.email];
  }
  return toList;
};

export const gatherCc = (activeData) => {
  let toList = [];
  if (activeData?.mode === "reply-email" && activeData?.reply?.cc) {
    toList = [...activeData?.reply?.cc];
  }
  return toList;
};

export const gatherBcc = (activeData) => {
  let toList = [];
  return toList;
};

export const gatherSubject = (activeData) => {
  return activeData?.reply?.subject || activeData?.task?.subject || undefined;
};

export const gatherBody = (activeData) => {
  return activeData?.reply?.body || "";
};

import { useState } from "react";
import { Button, Modal } from "@centrate-io/barn";
import { IconMail } from "@tabler/icons-react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import "./Gmail.scss";

const SignInButton = () => {
  const handleLogin = async () => {
    window.location.href = "http://localhost:5000/login/microsoft";
  };

  return (
    <Button block type="secondary" onClick={handleLogin}>
      Login with Microsoft
    </Button>
  );
};

function Gmail(props) {
  const [visible, setVisible] = useState(true);

  const login = useGoogleLogin({
    flow: "auth-code", // Specify the auth-code flow to get authorization code
    onSuccess: async ({ code }) => {
      // Send the authorization code to your backend
      // const tokens = await axios.post(
      //   "http://localhost:5001/auth/google/callback",
      //   {
      //     code,
      //   },
      // );
    },
    onError: (error) => {
      console.error("Login failed:", error);
    },
    scope: "https://www.googleapis.com/auth/gmail.send", // Specify the required scopes
  });

  const sendEmail = async () => {
    await axios.post("http://localhost:5001/gmail/send", {});
  };

  const sendOutlookEmail = async () => {
    await axios.post("http://localhost:5001/microsoft/send", {});
  };

  return (
    <Modal
      wrapClassName="gmail-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={true}
      width={640}
    >
      <div className="pp-modal-header">
        <div>
          <IconMail />
        </div>
        <h3>Gmail Testing</h3>
      </div>

      <Modal.Body>
        <div className="chat-window">
          <Button block type="primary" onClick={() => login()}>
            Login with Google
          </Button>
          <SignInButton />
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button block type="primary" onClick={sendEmail}>
          Send Google Email
        </Button>
        <Button block type="primary" onClick={sendOutlookEmail}>
          Send Outlook Email
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default Gmail;

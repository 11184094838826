import { Parser } from "@json2csv/plainjs";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";

export const validateEmail = (email) => {
  return email?.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  )
    ? true
    : false;
};
export const validateLastFour = (ssn) => {
  return ssn?.match(/^[0-9]{4}$/) ? true : false;
};
export const validateName = (name) => {
  return name?.match(/^(?=.{1,40}$)[a-zA-Z]+(?:[-' ][a-zA-Z]+)*$/)
    ? true
    : false;
};
export const validateZipCode = (zip) => {
  return zip?.match(/(^\d{5}$)|(^\d{5}-\d{4}$)/) ? true : false;
};
export const validateState = (state) => {
  return state?.match(
    /(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|PA|RI|S[CD]|T[NX]|UT|V[AT]|W[AIVY])/,
  )
    ? true
    : false;
};
export const validateAddress = (address) => {
  return address?.match(/^[a-zA-Z0-9\s.#,'-]*$/) && address?.length > 0
    ? true
    : false;
};
export const validateString = (name) => {
  return name && name?.length > 0 ? true : false;
};

export const debounce = (func, delay) => {
  let timeout;
  return (...args) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

export const validatePhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return false;
  const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
  if (phoneNumber && phoneNumber.isValid()) {
    return true;
  } else {
    return false;
  }
};
export const validateSocial = (ssn) => {
  return ssn?.match(/^\d{3}-?\d{2}-?\d{4}$/) ? true : false;
};
export const numberWithCommas = (x) => {
  return x ? x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";
};
export const formatBytes = (a) => {
  if (0 === a) return "0 Bytes";
  var c = 1024,
    d = 0,
    e = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(a) / Math.log(c));
  return parseFloat((a / Math.pow(c, f)).toFixed(d)) + " " + e[f];
};

export const FormatPhone = (nput) => {
  let newValue = "";
  if (nput[0] === "+" && nput[1] === "1") nput = nput.replace("+1", "");
  const input = nput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 6);
  const last = input.substring(6, 10);

  if (input.length > 6) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 6) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const NumbersOnly = (input) => {
  return (input || "").replace(/\D/g, "");
};

export const FormatSSN = (nput, isInput) => {
  let actualInput = nput || "";
  if (actualInput?.length <= 0 && !isInput) return <i>n/a</i>;
  if (actualInput?.length <= 0 && isInput) return "";
  let newValue = "";
  if (actualInput[0] === "+" && actualInput[1] === "1")
    actualInput = actualInput.replace("+1", "");
  const input = actualInput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 3);
  const middle = input.substring(3, 5);
  const last = input.substring(5, 9);

  if (input.length > 5) {
    newValue = `${zip}-${middle}-${last}`;
  } else if (input.length === 5) {
    newValue = `${zip}-${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}-${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const FormatDate = (nput) => {
  let actualInput = nput || "";
  if (actualInput?.length <= 0) return <i>n/a</i>;
  let newValue = "";
  if (actualInput[0] === "+" && actualInput[1] === "1")
    actualInput = actualInput.replace("+1", "");
  const input = actualInput.replace(/\D/g, "").substring(0, 10);
  const zip = input.substring(0, 2);
  const middle = input.substring(2, 4);
  const last = input.substring(4, 8);

  if (input.length > 5) {
    newValue = `${zip}/${middle}/${last}`;
  } else if (input.length === 5) {
    newValue = `${zip}/${middle}`;
  } else if (input.length > 3) {
    newValue = `${zip}/${middle}`;
  } else if (input.length === 3) {
    newValue = `${zip}`;
  } else if (input.length > 0) {
    newValue = `${zip}`;
  }
  return newValue;
};

export const formatMoney = (amount) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
    amount || 0,
  );

export const flatMoney = (amount) => {
  const val = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount || 0);
  return val.split(".")[0];
};

export const formatPercent = (val, points) => {
  return (val || 0).toFixed(points || 0) + "%";
};

export const prettyPhone = (phoneNumberString) => {
  if (!phoneNumberString) return <i>n/a</i>;
  const phoneNumber = parsePhoneNumber(phoneNumberString, "US");
  if (phoneNumber && phoneNumber.country === "US") {
    return phoneNumber.formatNational();
  } else if (phoneNumber) {
    return phoneNumber.formatInternational();
  } else {
    return phoneNumberString;
  }
};

function stripCSS(htmlString) {
  return htmlString.replace(/<style[\s\S]*?>[\s\S]*?<\/style>/gi, "");
}

export const stripHTML = (html) => {
  let newHTML = stripCSS(html || "");
  const div = document.createElement("div");
  div.innerHTML = newHTML;
  const text = div.textContent || div.innerText || "";
  return text;
};

export const stringifyValues = (data) => {
  // Base case: if data is a primitive value, return it as a string
  if (typeof data !== "object" || data === null) {
    return stripHTML(String(data));
  }

  // Handle arrays: recursively stringify each element
  if (Array.isArray(data)) {
    return stripHTML(data.map(stringifyValues).join(" "));
  }

  // Handle objects: recursively stringify each value
  return stripHTML(Object.values(data).map(stringifyValues).join(" "));
};

export const delay = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const capitalize = (string) => {
  if (!string) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const flatten = (data) => {
  const newObject = {};
  Object.keys(data).forEach((key) => {
    const tempObject = data[key];
    Object.keys(tempObject).forEach((field) => {
      newObject[field] = tempObject[field];
    });
  });
  return newObject;
};

export const parseAddress = (components) => {
  const now = {};
  components.forEach((c) => {
    now[c.types[0]] = {
      long: c.long_name,
      short: c.short_name,
    };
  });
  return now;
};

export const timeout = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const validateAccountNumber = (accountNumber) => {
  // Remove all non-digit characters (if any)
  let cleaned = (accountNumber || "").replace(/\D/g, "");

  // Check the length is between 4 and 17 digits
  if (cleaned.length >= 6 && cleaned.length <= 16) {
    return true;
  } else {
    return false;
  }
};

export const viewDocument = (pdfUrl) => {
  var link = document.createElement("a");
  link.href = pdfUrl;
  link.target = "_blank";
  link.click();
};

export const validateRoutingNumber = (routingNumber) => {
  // Remove all non-digit characters (if any)
  let cleaned = (routingNumber || "").replace(/\D/g, "");

  // Check if the length is exactly 9 digits
  if (cleaned.length !== 9) {
    return false;
  }

  // Convert the string to an array of integers
  let digits = cleaned.split("").map(Number);

  // Apply the checksum formula
  let checksum =
    3 * (digits[0] + digits[3] + digits[6]) +
    7 * (digits[1] + digits[4] + digits[7]) +
    1 * (digits[2] + digits[5] + digits[8]);

  // Validate the checksum
  return checksum % 10 === 0;
};

export const flattenObject = (obj, parentKey = "", result = {}) => {
  for (const [key, value] of Object.entries(obj)) {
    const newKey = parentKey ? `${parentKey}.${key}` : key;

    if (typeof value === "object" && value !== null && !Array.isArray(value)) {
      flattenObject(value, newKey, result);
    } else {
      result[newKey] = value;
    }
  }
  return result;
};

export const containsHTML = (str) => {
  if (!str?.toString()) return false;
  // Regular expression to detect HTML tags
  const htmlRegex = /<[^>]+>/g;
  return htmlRegex.test(str);
};

export const convertDates = (obj) => {
  const newObj = { ...obj };
  Object.keys(obj).forEach((r) => {
    const possibleDate = moment(obj[r]?.length === 13 ? obj[r] * 1 : null);
    if (possibleDate.isValid()) {
      newObj[r] = possibleDate.format("MM-DD-YYYY");
    }
  });
  return newObj;
};

export const extractVariables = (str) => {
  if (!str) return [];
  // Use a regular expression to match all {{variableName}} patterns
  const regex = /{{(.*?)}}/g;
  let match;
  const variables = [];

  // Iterate over all matches in the string
  while ((match = regex.exec(str)) !== null) {
    variables.push(match[1]);
  }

  // make unique
  const uniqueSet = new Set(variables);
  const uniqueVars = [...uniqueSet].filter((v) => v !== "");

  return uniqueVars;
};

export const downloadCSV = (jsonData, name) => {
  const json2csvParser = new Parser();
  const csv = json2csvParser.parse(jsonData);

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", name || "data.csv");
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const formatDuration = (sec) => {
  let seconds = parseInt(sec || 0) || 0;
  if (seconds <= 0) return "0 seconds";

  const units = [
    { label: "hour", seconds: 3600 },
    { label: "minute", seconds: 60 },
    { label: "second", seconds: 1 },
  ];

  const result = [];

  for (const unit of units) {
    const count = Math.floor(seconds / unit.seconds);
    if (count > 0) {
      result.push(`${count} ${unit.label}${count > 1 ? "s" : ""}`);
      seconds %= unit.seconds; // Update the remaining seconds
    }
  }

  return result.join(" ");
};

export const parseAndLimit = (value) => {
  // Parse the float
  const parsedValue = parseFloat(value || 0);

  // Check if the value is NaN
  if (isNaN(parsedValue)) {
    console.log("Invalid number");
  }

  // Limit to 2 decimal places
  return parsedValue.toFixed(2);
};

import React, { useState, useEffect } from "react";
import { InfoWindow } from "_styleguide";
import { Button } from "@centrate-io/barn";
import { Terms } from "../";
import { LongTerms } from "../Agreement/WoodsAgreement";
import { useApolloClient } from "@apollo/client";
import { getAgreementData } from "_graphql/queries/application";
import html2pdf from "html2pdf.js";
import "./ConfirmPopup.scss";

function ConfirmPopup(props) {
  const { form } = props;
  const client = useApolloClient();
  const [agreement, setAgreement] = useState();

  let plaidData = null;
  try {
    plaidData = form?.plaid ? JSON.parse(form?.plaid) : null;
  } catch (err) {
    console.log(err);
  }

  const getAgreement = async () => {
    const stateResponse = await client.query({
      query: getAgreementData,
      variables: {
        application_id: form?.id,
      },
    });
    setAgreement(stateResponse?.data?.getAgreementData);
  };

  const generatePDF = async () => {
    props?.setSaving(true);
    const contractElement = document.getElementById("contract-terms-pdf");

    const options = {
      margin: 0.5,
      filename: "Contract_Agreement.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "a4", orientation: "portrait" },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
    };

    // Generate PDF and get it as a blob
    const pdfBlob = await html2pdf()
      .set(options)
      .from(contractElement)
      .toPdf()
      .output("blob");

    const base64PDF = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result.split(",")[1]); // Remove data prefix
      reader.onerror = reject;
      reader.readAsDataURL(pdfBlob);
    });

    props.startSubscription(base64PDF);
  };

  useEffect(() => {
    getAgreement();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <InfoWindow className="confirm-iw" onClose={props.closeWindow}>
      <div className="pp-terms-scroll">
        <LongTerms
          form={props.form}
          plaidData={plaidData?.metadata}
          agreement={agreement}
        />
      </div>
      <Terms />
      {/*      <Button onClick={generatePDF} block>
        Download PDF
      </Button>*/}
      <Button
        disabled={!agreement}
        size="large"
        onClick={generatePDF}
        block
        className="green-btn"
        loading={props.saving}
      >
        Start my subscription
      </Button>
    </InfoWindow>
  );
}

export default ConfirmPopup;

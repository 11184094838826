/**
 * Crop an image with the given crop details.
 * @param {string} imageSrc - The source of the image to be cropped.
 * @param {Object} crop - The crop area details.
 * @returns {Promise<Blob>} - The cropped image as a Blob.
 */
export const getCroppedImg = (imageSrc, crop) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.crossOrigin = "anonymous"; // Needed if the image is from a different domain

    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      canvas.width = crop.width;
      canvas.height = crop.height;

      ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        crop.width,
        crop.height,
      );

      canvas.toBlob((blob) => {
        if (blob) {
          resolve(blob);
        } else {
          reject(new Error("Canvas is empty"));
        }
      }, "image/jpeg"); // You can change the format to 'image/png' if needed
    };

    image.onerror = (error) => reject(error);
  });
};

import React from "react";
import OutlookSVG from "_assets/logos/outlook.svg";
import { IconPlugConnected } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";

const OutlookConnect = (props) => {
  const handleLogin = async () => {
    window.location.href = `${process.env.REACT_APP_API_PATH}/login/microsoft?user_id=${props.user?.id}&company_id=${props.user?.company?.id}`;
  };

  return (
    <Button className="boxed" type="primary" onClick={handleLogin}>
      <IconPlugConnected /> Connect Outlook
    </Button>
  );
};

const Outlook = {
  type: "outlook",
  category: "email",
  logo: OutlookSVG,
  name: "Microsoft Outlook",
  description:
    "Login to your Microsoft Outlook account to enable sending emails and completing email tasks through PayPath.",
  Connect: OutlookConnect,
};

export default Outlook;

import React, { useState, useCallback } from "react";
import { AutoComplete, Input } from "antd";
import { gql, useLazyQuery } from "@apollo/client";
import { debounce } from "_assets/js/helpers";

const SEARCH_CUSTOMERS = gql`
  query getCustomers($query: String!) {
    getCustomers(query: $query) {
      id
      short_id
      status
      first_name
      last_name
      phone
      email
    }
  }
`;

const CustomerSearch = (props) => {
  const [options, setOptions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searchCustomers, { data }] = useLazyQuery(SEARCH_CUSTOMERS);

  // Debounced search function with 300ms delay
  const debouncedSearch = useCallback(
    debounce((value) => {
      if (value) {
        searchCustomers({ variables: { query: value } });
      }
    }, 510),
    [searchCustomers],
  );

  const handleSearch = (value) => {
    debouncedSearch(value);
  };

  const onSelect = (customer_id) => {
    const customerData = customers?.find((c) => c.id === customer_id);
    props.onSelect(customerData);
  };

  // Update the options when the data is received
  React.useEffect(() => {
    if (data && data.getCustomers) {
      const customerOptions = data.getCustomers.map((customer) => ({
        value: customer.id,
        label: `${customer.first_name} ${customer.last_name} - ${customer.email}`,
      }));
      setOptions(customerOptions);
      setCustomers(data.getCustomers);
    }
  }, [data]);

  return (
    <AutoComplete
      options={options}
      onSearch={handleSearch}
      onSelect={onSelect}
      placeholder="Search customers"
      popupClassName="pp-select-dropdown ppsd-task"
      className="pp-select"
    >
      <Input.Search />
    </AutoComplete>
  );
};

export default CustomerSearch;

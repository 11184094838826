import React from "react";
import PlaidSVG from "_assets/logos/plaid_icon.svg";
import { IconMailForward } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";

const PlaidConnect = (props) => {
  const handleLogin = async () => {
    const recipient = "engineering@paypath.ai";
    const subject = "Help setting up Plaid in PayPath";
    const body =
      "I am reaching out because I need to setup Plaid in PayPath so I can start verifying bank accounts";

    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Button className="boxed" type="primary" onClick={handleLogin}>
      <IconMailForward /> Contact Us
    </Button>
  );
};

const Plaid = {
  type: "plaid",
  category: "payments",
  company_only: true,
  contact_only: true,
  logo: PlaidSVG,
  name: "Plaid",
  description:
    "Contact us to setup Plaid so you can verify bank accounts and check balances through PayPath.",
  Connect: PlaidConnect,
};

export default Plaid;

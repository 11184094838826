import React, { createContext, useContext, useState } from "react";
import { delay } from "_assets/js/helpers";
import { Modal } from "@centrate-io/barn";

// Create the context
const BarContext = createContext();

// Create a provider component
export const BarProvider = ({ children }) => {
  const [activeData, setActiveData] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [callDuration, setCallDuration] = useState(null);
  const [minimizing, setMinimizing] = useState(false);

  const draftWarning = (customer, type, remainder) => {
    console.log("activeData", activeData);
    Modal.confirm({
      title: `Are you sure you want to start a new ${type}?`,
      okText: "Yes",
      content: `You currently have a ${activeData.type} in progress for ${activeData?.customer?.first_name || ""} ${activeData?.customer?.last_name || ""}, this will replace that item with a new ${type}.  All changes will be lost.`,
      onOk: async () => {
        setActiveData(null);
        await delay(1);
        remainder();
      },
    });
  };

  const setupCall = async (customer, task, override) => {
    const remainder = (customer, task, override) => {
      if (activeData?.mode === "no-call" && !override) {
        closeCall();
      } else if (activeData?.type === "call" && !override) {
        toggleMinimize();
      } else if (customer && !task) {
        setActiveData({
          type: "call",
          mode: "customer-call",
          customer: customer,
          task: null,
          minimize: false,
        });
      } else if (customer && task) {
        setActiveData({
          type: "call",
          mode: "task-call",
          customer: customer,
          task: task,
          minimize: false,
        });
      } else {
        setActiveData({
          type: "call",
          mode: "no-call",
          customer: null,
          task: null,
          minimize: false,
        });
      }
    };

    const runRemainder = () => remainder(customer, task, override);
    if (override && activeData && activeData.mode !== "no-call") {
      draftWarning(customer, "call", runRemainder);
    } else if (override) {
      setActiveData(null);
      await delay(1);
      runRemainder();
    } else {
      runRemainder();
    }
  };

  const setupText = async (customer, task, override) => {
    const remainder = (customer, task, override) => {
      if (activeData?.mode === "no-text" && !override) {
        closeCall();
      } else if (activeData?.type === "text" && !override) {
        toggleMinimize();
      } else if (customer && !task) {
        setActiveData({
          type: "text",
          mode: "customer-text",
          customer: customer,
          task: null,
          minimize: false,
        });
      } else if (customer && task) {
        setActiveData({
          type: "text",
          mode: "task-text",
          customer: customer,
          task: task,
          minimize: false,
        });
      } else {
        setActiveData({
          type: "text",
          mode: "no-text",
          customer: null,
          task: null,
          minimize: false,
        });
      }
    };

    const runRemainder = () => remainder(customer, task, override);
    if (override && activeData && activeData.mode !== "no-text") {
      draftWarning(customer, "text", runRemainder);
    } else if (override) {
      setActiveData(null);
      await delay(1);
      runRemainder();
    } else {
      runRemainder();
    }
  };

  const setupEmail = async (customer, task, override, reply) => {
    const remainder = (customer, task, override, reply) => {
      if (activeData?.mode === "no-email" && !override) {
        closeCall();
      } else if (activeData?.type === "email" && !override) {
        toggleMinimize();
      } else if (customer && !task && reply) {
        setActiveData({
          type: "email",
          mode: "reply-email",
          customer: customer,
          task: null,
          minimize: false,
          reply: reply,
        });
      } else if (customer && !task) {
        setActiveData({
          type: "email",
          mode: "customer-email",
          customer: customer,
          task: null,
          minimize: false,
        });
      } else if (customer && task) {
        setActiveData({
          type: "email",
          mode: "task-email",
          customer: customer,
          task: task,
          minimize: false,
        });
      } else {
        setActiveData({
          type: "email",
          mode: "no-email",
          customer: null,
          task: null,
          minimize: false,
        });
      }
    };

    const runRemainder = () => remainder(customer, task, override, reply);
    console.log("activeData", activeData);
    if (override && activeData && activeData.mode !== "no-email") {
      draftWarning(customer, "email", runRemainder);
    } else if (override) {
      setActiveData(null);
      await delay(1);
      runRemainder();
    } else {
      runRemainder();
    }
  };

  const setupMail = async (customer, task, override) => {
    const remainder = (customer, task, override) => {
      if (activeData?.mode === "no-mail" && !override) {
        closeCall();
      } else if (activeData?.type === "mail" && !override) {
        toggleMinimize();
      } else if (customer && !task) {
        setActiveData({
          type: "mail",
          mode: "customer-mail",
          customer: customer,
          task: null,
          minimize: false,
        });
      } else if (customer && task) {
        setActiveData({
          type: "mail",
          mode: "task-mail",
          customer: customer,
          task: task,
          minimize: false,
        });
      } else {
        setActiveData({
          type: "mail",
          mode: "no-mail",
          customer: null,
          task: null,
          minimize: false,
        });
      }
    };

    const runRemainder = () => remainder(customer, task, override);
    if (override && activeData && activeData.mode !== "no-mail") {
      draftWarning(customer, "mail", runRemainder);
    } else if (override) {
      setActiveData(null);
      await delay(1);
      runRemainder();
    } else {
      runRemainder();
    }
  };

  const toggleMinimize = async () => {
    console.log("toggling", activeData);
    if (activeData?.minimize) {
      setActiveData({ ...activeData, minimize: !activeData.minimize });
    } else if (!activeData?.minimize) {
      setMinimizing(true);
      await delay(400);
      setActiveData({ ...activeData, minimize: !activeData.minimize });
      setMinimizing(false);
    }
  };

  const closeCall = () => setActiveData(null);

  return (
    <BarContext.Provider
      value={{
        setupCall,
        setupText,
        setupEmail,
        setupMail,
        closeCall,
        toggleMinimize,
        activeData,
        customer,
        setCustomer,
        callDuration,
        setCallDuration,
        minimizing,
      }}
    >
      {children}
    </BarContext.Provider>
  );
};

// Custom hook to use the context
export const useBar = () => {
  return useContext(BarContext);
};

import { gql } from "@apollo/client";

export const saveTaskTemplate = gql`
  mutation saveTaskTemplate(
    $id: String
    $title: String!
    $type: String!
    $instructions: String!
    $subject: String
    $is_html: Boolean
    $variables: [TaskTemplateVariablesInput]!
  ) {
    saveTaskTemplate(
      id: $id
      title: $title
      type: $type
      instructions: $instructions
      subject: $subject
      is_html: $is_html
      variables: $variables
    )
  }
`;

export const completeTask = gql`
  mutation completeTask(
    $task_id: String!
    $skip: Boolean
    $note: String
    $notify: [String]
  ) {
    completeTask(task_id: $task_id, skip: $skip, note: $note, notify: $notify)
  }
`;

export const deleteTemplate = gql`
  mutation deleteTemplate($template_id: String!) {
    deleteTemplate(template_id: $template_id)
  }
`;

export const deleteTaskCustomer = gql`
  mutation deleteTaskCustomer($task_customer_id: String!) {
    deleteTaskCustomer(task_customer_id: $task_customer_id)
  }
`;

export const saveCustomerTask = gql`
  mutation saveCustomerTask(
    $id: String
    $application_id: String!
    $title: String!
    $type: String!
    $delay: Int
    $instructions: String!
    $start: String!
    $due_timestamp: String
    $assigned_user_id: String
    $assigned_team_id: String
    $template_id: String
  ) {
    saveCustomerTask(
      id: $id
      application_id: $application_id
      title: $title
      type: $type
      delay: $delay
      instructions: $instructions
      start: $start
      due_timestamp: $due_timestamp
      assigned_user_id: $assigned_user_id
      assigned_team_id: $assigned_team_id
      template_id: $template_id
    )
  }
`;

import { useState } from "react";
import { Toggle } from "_styleguide";
import { TAG_MAP } from "_constants/mapping";
import { containsHTML } from "_assets/js/helpers";
import "./TemplatePreview.scss";

function TemplatePreview(props) {
  const [demo, setDemo] = useState(false);
  const { form } = props;

  const hasHTML = containsHTML(form.instructions);
  const generateContent = () => {
    let content = form.instructions;
    if (demo) {
      form.variables.forEach((v) => {
        const variableToReplace = `{{${v.variable}}}`;
        const replaceWith = TAG_MAP[v.mapping]?.example;
        const regex = new RegExp(variableToReplace, "g");
        content = content.replace(regex, replaceWith);
      });
    }
    return content;
  };

  let preview = null;
  if (form.type === "text") {
    preview = (
      <div className="text-preview">
        <div className="message right">{generateContent()}</div>
      </div>
    );
  } else if (form.type === "call" || form.type === "general") {
    preview = (
      <div className="quill-preview">
        <div dangerouslySetInnerHTML={{ __html: generateContent() }} />
      </div>
    );
  } else if (form.type === "email" && !hasHTML) {
    preview = <div className="email-preview">{generateContent()}</div>;
  } else if (form.type === "email" && hasHTML) {
    preview = (
      <div className="html-preview">
        <iframe
          title="Email Preview"
          style={{ width: "100%", height: "500px", border: 0 }}
          srcDoc={generateContent()}
          sandbox="allow-same-origin"
        />
      </div>
    );
  }

  return (
    <div className="ct-preview">
      {form.variables?.length > 0 ? (
        <span>
          Toggle variables?
          <Toggle
            size="small"
            onChange={(checked) => setDemo(checked)}
            checked={demo}
          />
        </span>
      ) : null}
      {preview}
    </div>
  );
}

export default TemplatePreview;

import {
  IconUsers,
  IconLock,
  IconBuilding,
  IconBeach,
} from "@tabler/icons-react";
export const getCrumbs = (route) => {
  if (route === "companies") {
    return {
      title: "Companies",
      icon: <IconBuilding />,
      description: "All companies and partners at PayPath",
    };
  }
  if (route === "users") {
    return {
      title: "Users",
      icon: <IconUsers />,
      description: "Manage every user on PayPath",
    };
  }
  if (route === "playground") {
    return {
      title: "Playground",
      icon: <IconBeach />,
      description: "Have some fun in the sun!",
    };
  }
  return {
    title: "Admin Dashboard",
    icon: <IconLock />,
    description: "Manage your account",
  };
};

import React from "react";
import CRSSVG from "_assets/logos/crs.svg";
import { IconMailForward } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";

const CRSConnect = (props) => {
  const handleLogin = async () => {
    const recipient = "engineering@paypath.ai";
    const subject = "Help setting up CRS in PayPath";
    const body =
      "I am reaching out because I need to setup CRS in PayPath so I can start pulling credit reports";

    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Button className="boxed" type="primary" onClick={handleLogin}>
      <IconMailForward /> Contact Us
    </Button>
  );
};

const CRS = {
  type: "crs",
  category: "credit",
  company_only: true,
  contact_only: true,
  logo: CRSSVG,
  name: "CRS Credit API",
  description:
    "Contact us to help setup your CRS account so you can pull soft credit reports through PayPath.",
  Connect: CRSConnect,
};

export default CRS;

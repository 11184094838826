import { useState } from "react";
import { CallsClient } from "_graphql/clients/CallsClient";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconPhone } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, Select } from "_styleguide";
import { AREA_CODES } from "_constants/area_codes";
import { searchNumbers } from "_graphql/queries/twilio";
import { saveTwilioPhone } from "_graphql/mutations/twilio";
import { FormatPhone } from "_assets/js/helpers";
import "./TwilioPhone.scss";

function TwilioPhone(props) {
  const callsClient = CallsClient;
  const [step, setStep] = useState("areacode");
  const [areaCode, setAreaCode] = useState(undefined);
  const [phone, setPhone] = useState(undefined);
  const [callback, setCallback] = useState(props.user?.phone || undefined);
  const [numbers, setNumbers] = useState([]);
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);

  const getNumbers = async () => {
    setSaving(true);
    const queryData = await callsClient.query({
      variables: { area_code: areaCode },
      query: searchNumbers,
    });
    let twilioNumbers = queryData?.data?.searchNumbers || [];
    if (twilioNumbers?.length > 0) {
      setNumbers(twilioNumbers);
      setStep("number");
    } else {
      message.error("No numbers available for this area code, try again");
    }
    setSaving(false);
  };

  // Submit form
  const saveTwilio = async () => {
    setSaving(true);
    try {
      await callsClient.mutate({
        variables: {
          phone,
          callback,
        },
        mutation: saveTwilioPhone,
      });
      message.success(`Twilio Voice integration setup`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(
        getErrors(err) || "Error setting up integration, try again",
      );
      setSaving(false);
    }
  };

  const goBack = () => {
    setAreaCode(undefined);
    setStep("areacode");
    setNumbers(undefined);
    setPhone(undefined);
  };

  return (
    <Modal
      wrapClassName="twilio-phone-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <div className="pp-modal-header">
        <div>
          <IconPhone />
        </div>
        <h3>Setup Twilio Voice</h3>
      </div>

      <Modal.Body>
        {step === "areacode" ? (
          <Flex vertical={true}>
            <InputLabel htmlFor="areaCode">Select an area code *</InputLabel>
            <Select
              value={areaCode}
              onChange={(val) => setAreaCode(val)}
              showSearch
              size="medium"
              filterOption={(input, option) => {
                return (
                  option?.props?.srch
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                );
              }}
            >
              <Select.Option value="any" srch="Any">
                Any area code
              </Select.Option>
              {AREA_CODES.map((e, i) => (
                <Select.Option
                  value={e.code}
                  srch={`${e.code} (${e.state})`}
                  key={"area" + i + 1}
                >
                  {e.code} ({e.state})
                </Select.Option>
              ))}
            </Select>
          </Flex>
        ) : null}
        {step === "number" ? (
          <>
            <Flex vertical={true}>
              <InputLabel htmlFor="phone">
                Phone # for outgoing calls *
              </InputLabel>
              <Select
                placeholder="Select a phone number"
                value={phone}
                onChange={(val) => setPhone(val)}
                size="medium"
              >
                {numbers.map((e, i) => (
                  <Select.Option value={e.number} key={"pnrr" + i + 1}>
                    {e.friendly}
                  </Select.Option>
                ))}
              </Select>
            </Flex>
            <Flex vertical={true}>
              <InputLabel htmlFor="callback">
                Phone # to receive calls{" "}
                <span>Leave blank to disable incoming calls</span>
              </InputLabel>
              <FormInput
                id="callback"
                value={callback}
                onChange={(e) => setCallback(FormatPhone(e.target.value))}
                size="medium"
                placeholder="e.g. 631-294-2293..."
              />
            </Flex>
          </>
        ) : null}
      </Modal.Body>

      <Modal.Actions>
        {step === "areacode" ? (
          <>
            <Button block type="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              disabled={!areaCode}
              block
              loading={saving}
              type="primary"
              onClick={getNumbers}
            >
              Next
            </Button>
          </>
        ) : null}
        {step === "number" ? (
          <>
            <Button block type="secondary" onClick={goBack}>
              Back
            </Button>
            <Button
              onClick={saveTwilio}
              loading={saving}
              disabled={!phone}
              block
              type="primary"
            >
              Save
            </Button>
          </>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}

export default TwilioPhone;

import { CompanyAvatar, UserAvatar } from "_styleguide";
import { delay } from "_assets/js/helpers";
import moment from "moment";
import { Button } from "@centrate-io/barn";
import {
  IconCornerUpLeft,
  IconCornerUpLeftDouble,
  IconCornerUpRight,
} from "@tabler/icons-react";
import { useBar } from "_contexts/useBar";
import ResizableIframe from "./ResizableIframe";
import "./EmailOpen.scss";

function EmailOpen(props) {
  const { customer, closeCall, setupEmail } = useBar();
  const { c, userData } = props;
  const toMap = c.to.map((t) => {
    if (t.name?.length > 0) {
      return `${t.name} <${t.email}>`;
    } else {
      return t.email;
    }
  });
  const ccMap = c.cc.map((t) => {
    if (t.name?.length > 0) {
      return `${t.name} <${t.email}>`;
    } else {
      return t.email;
    }
  });
  const toOptions = c.to.map((t) => ({
    value: t.email,
    label: t.name?.length > 0 ? `${t.name} <${t.email}>` : t.email,
  }));
  const ccOptions = c.cc.map((t) => ({
    value: t.email,
    label: t.name?.length > 0 ? `${t.name} <${t.email}>` : t.email,
  }));
  const totalEmails = [...toMap, ...ccMap];

  const getForward = () => {
    let forward = `<br/><br/>---------- Forwarded message ---------`;
    forward += `<br/>From: ${
      c?.from?.name ? `${c?.from?.name} <${c?.from?.email}>` : c?.from?.email
    }`;
    forward += `<br/>Date: ${moment(parseInt(c.createdAt)).format("ddd M/D/YY h:mm A")}`;
    forward += `<br/>Subject: ${c?.subject}`;
    forward += `<br/>To: ${toMap.join("; ")}`;
    if (ccMap?.length > 0) {
      forward += `<br/>Cc: ${ccMap.join("; ")}`;
    }
    forward += `<br/><br/>`;
    return forward;
  };

  const createReply = async (type) => {
    closeCall();
    await delay(1);
    let toEmails = c.to.map((t) => t.email);
    let ccEmails = c.cc.map((t) => t.email);
    if (type === "reply") {
      setupEmail(customer, null, true, {
        to: [c?.from?.email],
        cc: [],
        bcc: [],
        body: null,
        subject: "Re: " + c?.subject,
        ext_email_id: c?.ext_email_id,
        ext_thread_id: c?.ext_thread_id,
        thread_id: props.thread?.id,
        reloadConversation: props.reloadConversation,
        reloadThreads: props.reloadThreads,
        options: [...toOptions, ...ccOptions],
      });
    } else if (type === "reply-all") {
      setupEmail(customer, null, true, {
        to: toEmails,
        cc: ccEmails,
        bcc: [],
        body: null,
        subject: "Re: " + c?.subject,
        ext_email_id: c?.ext_email_id,
        ext_thread_id: c?.ext_thread_id,
        thread_id: props.thread?.id,
        reloadConversation: props.reloadConversation,
        reloadThreads: props.reloadThreads,
        options: [...toOptions, ...ccOptions],
      });
    } else if (type === "forward") {
      setupEmail(customer, null, true, {
        to: [],
        cc: [],
        bcc: [],
        body: c?.body ? `${getForward()}${c.body}` : null,
        subject: "Fwrd: " + c?.subject,
        reloadConversation: props.reloadConversation,
        reloadThreads: props.reloadThreads,
        options: [...toOptions, ...ccOptions],
      });
    }
  };

  return (
    <div className="convo-open">
      <div className="co-header">
        {c?.user ? (
          <CompanyAvatar user={userData} />
        ) : (
          <UserAvatar name={c?.from?.name || c?.from?.email} />
        )}
        <div className="convo-info">
          {c?.from?.name ? (
            <h5>{`${c?.from?.name} <${c?.from?.email}>`}</h5>
          ) : (
            <h5>{c?.from?.email}</h5>
          )}
          <p className="convo-preview">
            <b>To:&nbsp;&nbsp;</b>
            {toMap.join("; ")}
          </p>
          {ccMap?.length > 0 ? (
            <p className="convo-preview">
              <b>Cc:&nbsp;&nbsp;</b>
              {ccMap.join("; ")}
            </p>
          ) : null}
        </div>
        <div className="convo-date">
          {moment(parseInt(c.createdAt)).format("ddd M/D/YY h:mm A")}
        </div>
      </div>
      <div className="convo-preview">
        <ResizableIframe c={c} />
      </div>
      <div className="convo-actions">
        <Button
          size="small"
          className="boxed"
          type="blanco"
          onClick={() => createReply("reply")}
        >
          <IconCornerUpLeft /> Reply
        </Button>
        {totalEmails?.length > 1 ? (
          <Button
            size="small"
            className="boxed"
            type="blanco"
            onClick={() => createReply("reply-all")}
          >
            <IconCornerUpLeftDouble /> Reply All
          </Button>
        ) : null}
        <Button
          size="small"
          className="boxed"
          type="blanco"
          onClick={() => createReply("forward")}
        >
          <IconCornerUpRight /> Forward
        </Button>
      </div>
    </div>
  );
}

export default EmailOpen;

import "./BarIcon.scss";

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secs = seconds % 60;
  return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
};

function BarIcon(props) {
  const isActive = props.data?.type === props.name;
  // const disabled =
  //   props.type === "outreach" &&
  //   props.data?.type &&
  //   props.data?.type !== props.name;

  const isOnCall =
    props.callDuration !== null && props.callDuration > 0 ? true : false;

  const onClickAction = () => {
    if (isActive) {
      props.minimize();
    } else {
      props.actions[props.name]();
    }
  };

  return (
    <div
      onClick={props.onClick || onClickAction}
      className={`bar-icon ${isActive ? "active" : ""} ${isOnCall ? "on-call" : ""}`}
    >
      {props.icon}
      {isOnCall ? (
        <div className="call-duration">{formatTime(props.callDuration)}</div>
      ) : null}
    </div>
  );
}

export default BarIcon;

import { useState, useEffect } from "react";
import { Button, Modal } from "@centrate-io/barn";
import { IconPhone } from "@tabler/icons-react";
import { getToken } from "./_helpers/twilio";
import { Device } from "@twilio/voice-sdk";
import io from "socket.io-client";
import "./Calling.scss";

// const APPLICATION_ID = "665dfe41354ab18e895c3da0";

function Calling(props) {
  const [device, setDevice] = useState(null);
  const [isCalling, setIsCalling] = useState(false);
  const [visible, setVisible] = useState(true);
  const [callStatus, setCallStatus] = useState(null);
  const [socket, setSocket] = useState(null);

  const initializeDevice = async () => {
    try {
      const token = await getToken();
      const newDevice = new Device(token, {
        codecPreferences: ["opus", "pcmu"],
        enableRingingState: true,
      });

      // Disable dumb sounds
      newDevice.audio.outgoing(false);
      newDevice.audio.disconnect(false);

      newDevice.on("ready", () => {
        console.log("Device is ready");
      });

      newDevice.on("error", (error) => {
        console.log("Error with Twilio device:", error);
        handleDisconnect();
      });

      newDevice.on("connect", () => {
        console.log("Device connected");
      });

      newDevice.on("disconnect", () => {
        console.log("Call ended");
        handleDisconnect();
      });

      setDevice(newDevice);
    } catch (error) {
      console.error("Error initializing device:", error);
    }
  };

  const makeCall = async () => {
    if (device) {
      const params = {
        customer_phone: "+15133098578",
        user_phone: "+15185323125",
        fromDialer: "playground",
        socketId: socket,
      };
      const call = await device.connect({ params });
      call.on("error", (error) => {
        handleDisconnect();
      });
      call.on("disconnect", () => {
        handleDisconnect();
      });
      setIsCalling(true);
    }
  };

  const hangUp = () => {
    if (device) {
      device.disconnectAll();
      setIsCalling(false);
    }
  };

  const handleDisconnect = () => {
    setIsCalling(false);
    if (device) {
      device.disconnectAll();
    }
  };

  useEffect(() => {
    initializeDevice();
  }, []);

  useEffect(() => {
    // Initialize the socket inside the component
    const newSocket = io(process.env.REACT_APP_CALLS_API_PATH, {
      withCredentials: true,
    }); // Replace with your server's URL

    newSocket.on("connect", () => {
      setSocket(newSocket.id);
    });

    // Listen for call status updates from the server
    newSocket.on("callStatus", (data) => {
      console.log("Received call status:", data);
      setCallStatus(data.status);
    });

    // Clean up the socket connection when the component unmounts
    return () => {
      newSocket.off("connect");
      newSocket.off("callStatus");
      newSocket.disconnect();
    };
  }, []);

  return (
    <Modal
      wrapClassName="calling-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={true}
      width={640}
    >
      <div className="pp-modal-header">
        <div>
          <IconPhone />
        </div>
        <h3>Calling</h3>
      </div>

      <Modal.Body>
        <div>
          <div>Device: {device ? "Connected" : "Connecting..."}</div>
          <div>Socket Id: {socket}</div>
          <div>================================</div>
          <div>Is Calling?: {isCalling ? "True" : "False"}</div>
          <div>Call Status: {callStatus || "waiting"}</div>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button block type="danger" onClick={hangUp} disabled={!isCalling}>
          Hang Up
        </Button>
        <Button
          block
          type="primary"
          onClick={makeCall}
          disabled={isCalling || !device || !socket}
        >
          {isCalling ? "Calling 513-309-8578..." : "Call 513-309-8578"}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default Calling;

import { useState, useRef, useEffect } from "react";
import { Button, Modal } from "@centrate-io/barn";
import { IconMail } from "@tabler/icons-react";
import { emailOne } from "./_templates/emailOne";
import "./Templates.scss";

function Templates(props) {
  const [visible, setVisible] = useState(true);
  const iframeRef = useRef(null);

  useEffect(() => {
    const iframeDoc =
      iframeRef.current.contentDocument ||
      iframeRef.current.contentWindow.document;

    iframeDoc.open();
    iframeDoc.write(emailOne);
    iframeDoc.close();

    // Set iframe content as editable
    iframeDoc.designMode = "on";
  }, [emailOne]);

  const handleSave = () => {
    const iframeDoc =
      iframeRef.current.contentDocument ||
      iframeRef.current.contentWindow.document;

    // Get the full HTML from the iframe
    const updatedHtml = iframeDoc.documentElement.outerHTML;
    // You can now save the updated HTML to a server or use it as needed
    console.log(updatedHtml);
  };

  return (
    <Modal
      wrapClassName="templates-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={true}
      width={640}
    >
      <div className="pp-modal-header">
        <div>
          <IconMail />
        </div>
        <h3>Email Template Testing</h3>
      </div>

      <Modal.Body>
        <iframe
          title="email-editor"
          ref={iframeRef}
          style={{ width: "100%", height: "400px", border: "1px solid #ccc" }}
        />
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button block type="primary" onClick={handleSave}>
          Log Variables
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default Templates;

import { gql } from "@apollo/client";

export const ownerUsers = gql`
  query {
    ownerUsers {
      id
      short_id
      name
      email
      phone
      role
      application_count
      subscription_count
      invited_date
      joined_date
      createdAt

      companies {
        id
        company {
          id
          short_id
          name
          role
          parent_company_id
        }
      }
    }
  }
`;

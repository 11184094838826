import { IconBraces } from "@tabler/icons-react";
import {
  FormInput,
  InputLabel,
  Flex,
  TextArea,
  Quill,
  AlertBox,
  TaskBadge,
  RadioGroup,
  Select,
} from "_styleguide";
import Editor from "@monaco-editor/react";
import { extractVariables } from "_assets/js/helpers";
import { Tooltip } from "@centrate-io/barn";
import "./TemplateContent.scss";

function TemplateContent(props) {
  const { validation, form, updateForm, saving } = props;

  const titles = {
    email: "Email Content",
    call: "Call Script or Instructions",
    general: "General Instructions",
    text: "Text Message Content",
  };

  const variables = extractVariables(form?.instructions);

  const variablesTooltip = (
    <div className="vtt">
      <div className="vtt-info">{`${variables.length} unique variable(s):`}</div>
      {variables.map((v, i) => (
        <div key={"vtt-" + i}>{v}</div>
      ))}
    </div>
  );

  return (
    <div className="ct-content">
      <Flex className="ct-top">
        <Flex vertical={true}>
          <InputLabel htmlFor="title" {...validation.title}>
            Title *
          </InputLabel>
          <FormInput
            id="title"
            value={form.title}
            size="medium"
            disabled={saving}
            onChange={(e) => updateForm("title", e.target.value)}
            placeholder="e.g. 30 Day Follow-up...."
            {...validation.title}
          />
        </Flex>
        <Flex className="ct-type" vertical={true}>
          <InputLabel htmlFor="type" {...validation.type}>
            Type *
          </InputLabel>
          <Select
            value={form.type}
            onChange={(val) => updateForm("type", val)}
            size="extra-medium"
            popupClassName="pp-select-dropdown ppsd-task"
            placeholder="---"
            disabled={saving}
            {...validation.type}
          >
            <Select.Option value="general">
              <TaskBadge type="general" />
              General
            </Select.Option>
            <Select.Option value="call">
              <TaskBadge type="call" />
              Call
            </Select.Option>
            <Select.Option value="email">
              <TaskBadge type="email" />
              Email
            </Select.Option>
            <Select.Option value="text">
              <TaskBadge type="text" />
              Text Message
            </Select.Option>
          </Select>
        </Flex>
      </Flex>
      <Flex
        vertical={true}
        style={{ display: form.type === "email" ? "flex" : "none" }}
      >
        <InputLabel htmlFor="subject" {...validation.subject}>
          Subject *
        </InputLabel>
        <FormInput
          id="subject"
          value={form.subject}
          size="medium"
          disabled={saving}
          onChange={(e) => updateForm("subject", e.target.value)}
          placeholder="e.g. Welcome to Company...."
          {...validation.subject}
        />
      </Flex>
      <Flex vertical={true} style={{ display: form.type ? "flex" : "none" }}>
        <InputLabel htmlFor="instructions" {...validation.instructions}>
          {titles[form.type]}
          <Tooltip title={variablesTooltip}>
            <span
              className={`contains-variable ${variables.length > 0 ? "variable" : ""}`}
            >
              <IconBraces />
            </span>
          </Tooltip>
        </InputLabel>

        <div className="ct-editor">
          {form.type === "email" ? (
            <RadioGroup
              size="small"
              onChange={(e) =>
                updateForm("is_html", e.target.value === "html" ? true : false)
              }
              buttonStyle="solid"
              value={form.is_html ? "html" : "text"}
            >
              <RadioGroup.Button value="text">Text</RadioGroup.Button>
              <RadioGroup.Button value="html">HTML</RadioGroup.Button>
            </RadioGroup>
          ) : null}
          {form.type === "text" ? (
            <TextArea
              value={form.instructions}
              onChange={(e) => updateForm("instructions", e.target.value)}
              size="large"
            />
          ) : (
            <>
              {form.type === "email" && form.is_html ? (
                <Editor
                  height="296px"
                  defaultLanguage="html"
                  value={form.instructions}
                  onChange={(val) => updateForm("instructions", val)}
                  theme="light"
                  options={{
                    wordWrap: false,
                    minimap: {
                      enabled: false,
                    },
                  }}
                />
              ) : (
                <Quill
                  value={form.instructions}
                  onChange={(val) => updateForm("instructions", val)}
                />
              )}
            </>
          )}
          <AlertBox type="info">
            <b>VARIABLES:</b>&nbsp;&nbsp;You can add variables by placing them
            in a tag <b>{`{{variableName}}`}</b> and map them to real company,
            user, and customer data on the next screen
          </AlertBox>
        </div>
      </Flex>
    </div>
  );
}

export default TemplateContent;

import React from "react";
import MintSVG from "_assets/logos/mint.svg";
import { IconMailForward } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";

const MintConnect = (props) => {
  const handleLogin = async () => {
    const recipient = "engineering@paypath.ai";
    const subject = "Help setting up Mint in PayPath";
    const body =
      "I am reaching out because I need to setup Mint in PayPath so I can start collecting payments";

    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Button className="boxed" type="primary" onClick={handleLogin}>
      <IconMailForward /> Contact Us
    </Button>
  );
};

const Mint = {
  type: "mint",
  category: "payments",
  company_only: true,
  contact_only: true,
  logo: MintSVG,
  name: "Mint Group Business Solutions",
  description:
    "Contact us to help setup your Mint account so you can collect payments through PayPath.",
  Connect: MintConnect,
};

export default Mint;

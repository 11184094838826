import { gql } from "@apollo/client";

export const adminCalls = gql`
  query adminCalls(
    $id: ID
    $results: Int
    $page: Int
    $sortField: String
    $sortOrder: String
  ) {
    adminCalls(
      id: $id
      results: $results
      page: $page
      sortField: $sortField
      sortOrder: $sortOrder
    ) {
      data {
        id
        call_id
        status
        duration
        mode
        from_number
        to_number
        connected
        no_answer
        incoming_call
        createdAt

        user {
          id
          name
        }

        task {
          id
          title
        }
      }
      total
      pageTotal
    }
  }
`;

import React from "react";
import {
  IconClock,
  IconUser,
  IconRotateClockwise2,
  IconCheck,
  IconAlignLeft,
  IconTrash,
  IconPencil,
  IconUsersGroup,
  IconPhone,
  IconMessage,
  IconMail,
} from "@tabler/icons-react";
import { TaskBadge, Tag, Flex } from "_styleguide";
import { Button, Tooltip } from "@centrate-io/barn";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import moment from "moment";
import { stripHTML } from "_assets/js/helpers";
import "./TaskCard.scss";

function TaskCard(props) {
  const { customer, setupCall, setupText, setupEmail } = useBar();
  const { integrations } = useIntegrations();
  const { task } = props;

  let timing = null;
  let completed = false;
  const format = "ddd MMM Do, YYYY";
  if (task.completed_timestamp) {
    timing = moment(parseInt(task.completed_timestamp)).format("MMM Do, YYYY");
    completed = true;
  } else if (task.due_timestamp) {
    const dueDays = task.due_days ? Math.abs(task.due_days) : null;
    if (task.due_days !== null && dueDays < 60) {
      if (task.due_days === 0) {
        timing = "Immediately";
      } else if (task.due_days < 0) {
        timing = `Due ${dueDays} day(s) ago`;
      } else if (task.due_days > 0) {
        timing = `Due in ${dueDays} day(s)`;
      }
    } else {
      timing = "Due on " + moment(parseInt(task.due_timestamp)).format(format);
    }
  } else if (task.start === "previous") {
    if (task.delay <= 0) {
      timing = "Immediately after previous task";
    } else {
      timing = `${task.delay} day(s) after previous task`;
    }
  } else {
    timing = moment(parseInt(task.estimated_due_timestamp)).format(format);
  }

  const expandTask = () => {
    const expanded = [...props.expanded];
    if (expanded.includes(task.id)) {
      props.setExpanded(expanded.filter((t) => t !== task.id));
    } else {
      expanded.push(task.id);
      props.setExpanded(expanded);
    }
  };

  const editTask = () => {
    props.manageTask({
      id: task.id,
      title: task.title,
      type: task.type,
      instructions: task.instructions,
      start: task.start,
      delay: task.delay,
      order: task.order,
      assigned_user_id: task.assigned_user_id,
      assigned_team_id: task.assigned_team_id,
      template_id: task.template_id,
    });
  };

  const isExpanded = props.expanded?.includes(task.id);

  const canCall =
    integrations?.call && task?.type === "call" && !props.readOnly
      ? true
      : false;
  const canText =
    integrations?.text && task?.type === "text" && !props.readOnly
      ? true
      : false;
  const canEmail =
    integrations?.email && task?.type === "email" && !props.readOnly
      ? true
      : false;

  const showComplete =
    !task.completed_timestamp &&
    (task.type === "general" ||
      (task.type === "call" && !integrations?.call) ||
      (task.type === "text" && !integrations?.call) ||
      (task.type === "email" && !integrations?.call)) &&
    !props.readOnly;

  const actionTask = { ...task, reload: props.reload };

  return (
    <div className={`app-task-card${completed ? " done" : ""}`}>
      <div className="tc-actions">
        {!task.completed_timestamp && !props.readOnly ? (
          <>
            <Button
              onClick={() => props.removeCustomerTask(task.id)}
              type="nope"
              size="small"
              className="boxed"
            >
              <IconTrash />
            </Button>
            <Button
              onClick={editTask}
              type="blanco"
              size="small"
              className="boxed"
            >
              <IconPencil />
            </Button>
          </>
        ) : null}
        {showComplete ? (
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => props.setCompleteModal(task.id)}
          >
            <IconCheck /> Complete
          </Button>
        ) : null}
        {!task.completed_timestamp && canCall ? (
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => {
              setupCall(customer, actionTask, true);
            }}
          >
            <IconPhone /> Call
          </Button>
        ) : null}
        {!task.completed_timestamp && canText ? (
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => {
              setupText(customer, actionTask, true);
            }}
          >
            <IconMessage /> Text
          </Button>
        ) : null}

        {!task.completed_timestamp && canEmail ? (
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => {
              setupEmail(customer, actionTask, true);
            }}
          >
            <IconMail /> Email
          </Button>
        ) : null}
      </div>
      <Flex gap={16}>
        <div className="tc-content">
          <div className="tc-header">
            <h4>
              {task.completed && !task.skipped ? <IconCheck /> : null}
              {task.skipped ? <IconRotateClockwise2 /> : null}
              {task.title}
            </h4>
            <Tooltip title="Expand">
              <Button type="secondary" className="boxed" onClick={expandTask}>
                <IconAlignLeft />
              </Button>
            </Tooltip>
          </div>
          {isExpanded ? (
            <p>
              {stripHTML(
                task.template?.instructions || task.instructions || "",
              )}
            </p>
          ) : null}{" "}
          {isExpanded && task.note ? (
            <p>
              <b>Note:</b>&nbsp;&nbsp;{task.note}
            </p>
          ) : null}
          <div className="tc-info">
            <TaskBadge full="true" type={task.type} />
            {task.completed_timestamp ? (
              <Tooltip title={task.skipped ? "Skipped" : "Completed"}>
                <Tag size="small">
                  {task.skipped ? <IconRotateClockwise2 /> : <IconCheck />}
                  {timing}
                </Tag>
              </Tooltip>
            ) : (
              <Tag size="small">
                <IconClock />
                {timing}
              </Tag>
            )}
            {task?.completed_user?.id ? (
              <Tooltip title={`Completed by ${task?.completed_user?.name}`}>
                <Tag size="small">
                  <IconUser />
                  {task?.completed_user?.name}
                </Tag>
              </Tooltip>
            ) : null}
            {task.assigned_user && !task?.completed_user?.id ? (
              <Tooltip
                title={`Assigned to ${task?.assigned_user?.name} ${task.assigned_team ? `(${task.assigned_team?.name})` : ""}`}
              >
                <Tag size="small">
                  <IconUser />
                  {task?.assigned_user?.name}{" "}
                  {task.assigned_team ? `(${task.assigned_team?.name})` : ""}
                </Tag>
              </Tooltip>
            ) : null}
            {task.assigned_team &&
            !task?.completed_user?.id &&
            !task?.assigned_user ? (
              <Tooltip title={`Assigned to ${task.assigned_team?.name}`}>
                <Tag size="small">
                  <IconUsersGroup />
                  {task.assigned_team?.name}
                </Tag>
              </Tooltip>
            ) : null}
          </div>
        </div>
      </Flex>
    </div>
  );
}

export default TaskCard;

import { useState } from "react";
import { Button, Modal } from "@centrate-io/barn";
import { IconMail } from "@tabler/icons-react";
// import axios from "axios";
// import { useIntegrations } from "_contexts/useIntegrations";
import { CallsClient } from "_graphql/clients/CallsClient";
import { getDocusignTemplates } from "_graphql/queries/docusign";
import "./Docusign.scss";

function Docusign(props) {
  // const { integrations } = useIntegrations();
  const callsClient = CallsClient;
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);

  // Load Text Messages
  const getTemplates = async () => {
    setSaving(true);
    const queryData = await callsClient.query({
      query: getDocusignTemplates,
    });
    console.log(
      "queryData?.data?.getDocusignTemplates",
      queryData?.data?.getDocusignTemplates,
    );
    setSaving(false);
  };
  return (
    <Modal
      wrapClassName="docusign-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={true}
      width={640}
    >
      <div className="pp-modal-header">
        <div>
          <IconMail />
        </div>
        <h3>Docusign Testing</h3>
      </div>

      <Modal.Body>
        <div className="chat-window">
          <Button block type="primary" loading={saving} onClick={getTemplates}>
            Get Docusign Templates
          </Button>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button block type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button block type="primary" onClick={() => setVisible(false)}>
          Also Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default Docusign;

import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { adminApplicationActivity } from "App/Admin/_graphql/queries/activity";
import { Loading } from "_styleguide";
import moment from "moment";
import Status from "./_components/Status";
import Note from "./_components/Note";
import CreditReport from "./_components/CreditReport";
import Tradelines from "./_components/Tradelines";
import Call from "./_components/Call";
import Email from "./_components/Email";
import Text from "./_components/Text";
import Outreach from "./_components/Outreach";
import Document from "./_components/Document";
import Payment from "./_components/Payment";
import Task from "./_components/Task";
import "./Activity.scss";

function Activity(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [activityData, setActivityData] = useState([]);

  // Load Entries
  const gather = async () => {
    const notesData = await client.query({
      variables: { application_id: props?.applicationId },
      query: adminApplicationActivity,
    });
    setActivityData(notesData?.data?.adminApplicationActivity || []);
    setLoading(false);
  };

  useEffect(() => {
    gather();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createDates = (activity) => {
    let dates = [];
    activity.forEach((data) => {
      dates.push(moment(data.createdAt * 1).format("dddd, MMMM Do YYYY"));
    });
    return [...new Set(dates.reverse())].reverse();
  };

  const mapActivity = (a) => {
    const globalProps = {
      key: a.id,
      activity: a,
      setTab: props.setTab,
      myself: a?.user?.id === props?.user?.id,
      customer: props.app,
    };
    if (a.type === "call") return <Call {...globalProps} />;
    if (a.type === "text") return <Text {...globalProps} />;
    if (a.type === "email") return <Email {...globalProps} />;
    if (a.type === "status") return <Status {...globalProps} />;
    if (a.type === "note") return <Note {...globalProps} />;
    if (a.type === "credit_report") return <CreditReport {...globalProps} />;
    if (a.type === "tradelines") return <Tradelines {...globalProps} />;
    if (a.type === "outreach") return <Outreach {...globalProps} />;
    if (a.type === "document") return <Document {...globalProps} />;
    if (a.type === "payment") return <Payment {...globalProps} />;
    if (a.type === "task") return <Task {...globalProps} />;

    return null;
  };

  let dateList = null;
  if (activityData.length > 0) {
    const dates = createDates(activityData);
    dateList = dates.map((item, i) => {
      let activityList = activityData.map((a) => {
        if (moment(a.createdAt * 1).format("dddd, MMMM Do YYYY") === item) {
          return mapActivity(a);
        }
        return null;
      });
      return (
        <div key={"activitygroup-" + i} date={item} className="date-section">
          <div className="activity-date">
            <span>
              {item === moment().format("dddd, MMMM Do YYYY") ? "Today" : item}
            </span>
          </div>
          {activityList}
        </div>
      );
    });
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="admin-application-activity">
      <div className="note-content">
        {activityData.length > 0 ? dateList : "No activity yet"}
      </div>
    </div>
  );
}

export default Activity;

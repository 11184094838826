import { Text } from "@centrate-io/barn";
import { IconCoin, IconCalendar } from "@tabler/icons-react";
import { formatMoney } from "_assets/js/helpers";
import moment from "moment";
import { formatDate } from "../../../_helpers/generatePayments";

export const getColumns = () => {
  return [
    {
      title: "",
      dataIndex: "number",
      key: "number",
      width: 20,
      align: "center",
      render: (number, data) => {
        return <Text>{data.id}</Text>;
      },
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Payment Date
        </div>
      ),
      dataIndex: "draft_date",
      key: "draft_date",
      render: (draft_date) => (
        <Text>
          {moment(formatDate(draft_date)).utc().format("MM/DD/YY - dddd")}
        </Text>
      ),
    },

    {
      title: (
        <div className="column-name">
          <IconCoin />
          Amount
        </div>
      ),
      dataIndex: "amount",
      key: "amount",
      render: (amount) => <Text>{formatMoney(amount)}</Text>,
    },
  ];
};

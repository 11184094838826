import React, { useState } from "react";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import Companies from "./Companies/Companies";
import Users from "./Users/Users";
import Playground from "./Playground/Playground";
import Company from "./_components/Company/Company";
import { IconBuilding, IconUsers, IconBeach } from "@tabler/icons-react";
import { Header, Text } from "@centrate-io/barn";
import { Layout, Menu } from "antd";
import { getSelectedKeys } from "_helpers/routing";
import "./Owner.scss";

const { Content, Sider } = Layout;

const getItem = (label, key, icon, children) => ({
  key,
  icon,
  children,
  label,
});

function Owner(props) {
  const [collapsed, setCollapsed] = useState(false);
  const [currentCount, setCurrentCount] = useState();
  const [crumbs, setCrumbs] = useState();

  let location = useLocation();
  const path = location?.pathname;
  const tablePaths = ["/"];

  const globalProps = {
    setCurrentCount,
    setCrumbs,
    user: props.user,
  };

  let companies = [];
  const isSuperAdmin = props?.user?.role === "super";
  if (isSuperAdmin) {
    companies = [
      getItem(<Link to="/">Companies</Link>, "companies", <IconBuilding />),
      getItem(<Link to="/users">Users</Link>, "users", <IconUsers />),
    ];
  }

  let play = [];
  if (isSuperAdmin && process.env.NODE_ENV === "development") {
    play = [
      { type: "divider" },
      getItem(
        <Link to="/playground">Playground</Link>,
        "playground",
        <IconBeach />,
      ),
    ];
  }

  const menuKeys = getSelectedKeys(location?.pathname, false, false);
  const isMasq = props?.user?.masq ? true : false;

  return (
    <Layout className={isMasq ? "layout-owner masq" : "layout-owner"} hasSider>
      <Sider
        width={240}
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        <Company user={props?.user} collapsed={collapsed} />
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={menuKeys}
          items={[...companies, ...play]}
        />
      </Sider>
      <Layout className={`site-layout ${collapsed ? "collapsed" : ""}`}>
        <Content
          className={`site-content ${tablePaths.includes(path) && "sheet"}`}
        >
          <div className="admin-header">
            {crumbs ? (
              <div className="crumbs">
                <Header type="display" size="5">
                  {crumbs?.icon}
                  {crumbs?.title}
                  <span className="current-count">
                    {currentCount && currentCount > 0
                      ? ` (${currentCount})`
                      : null}
                  </span>
                  {crumbs?.tag}
                </Header>
                <Text type="helper">{crumbs?.description}</Text>
              </div>
            ) : null}
          </div>
          <div className="admin-body">
            <Routes>
              {isSuperAdmin && (
                <Route
                  exact
                  path="/"
                  id="companies"
                  element={<Companies {...props} {...globalProps} />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  exact
                  path="/users"
                  id="users"
                  element={<Users {...props} {...globalProps} />}
                />
              )}
              {isSuperAdmin && (
                <Route
                  exact
                  path="/playground"
                  id="playground"
                  element={<Playground {...props} {...globalProps} />}
                />
              )}
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
  );
}

export default Owner;

import { gql } from "@apollo/client";

export const signupCompany = gql`
  mutation signupCompany(
    $company_id: ID!
    $company: String!
    $name: String!
    $email: String!
    $phone: String!
    $password: String!
  ) {
    signupCompany(
      company_id: $company_id
      company: $company
      name: $name
      email: $email
      phone: $phone
      password: $password
    )
  }
`;

export const saveCompany = gql`
  mutation saveCompany($company: CompanyInput!) {
    saveCompany(company: $company)
  }
`;

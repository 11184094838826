import gql from "graphql-tag";

export const searchNumbers = gql`
  query searchNumbers($area_code: String) {
    searchNumbers(area_code: $area_code) {
      number
      friendly
    }
  }
`;

import React, { useState } from "react";
import { Tag } from "_styleguide";
import { Button, Tooltip } from "@centrate-io/barn";
import { IconBuildingBank } from "@tabler/icons-react";
import ActiveAccount from "../../_modals/ActiveAccount/ActiveAccount";
import RemoveAccount from "../../_modals/RemoveAccount/RemoveAccount";
import "./Accounts.scss";

function Accounts(props) {
  const [activeModal, setActiveModal] = useState(false);
  const [removeModal, setRemoveModal] = useState(false);
  const { accounts } = props;

  const activeAccount = props?.app?.active_bank_account;

  accounts.sort((a, b) => {
    const aActive = a.id === activeAccount;
    if (aActive) {
      return -1;
    } else {
      return 1;
    }
  });

  return (
    <div className="body-accounts">
      {activeModal ? (
        <ActiveAccount
          account={activeModal}
          activeAccount={activeModal.id === activeAccount}
          removeModal={() => setActiveModal(false)}
          reload={props.reload}
          reloadApplication={props.reloadApplication}
        />
      ) : null}
      {removeModal ? (
        <RemoveAccount
          account={removeModal}
          activeAccount={removeModal.id === activeAccount}
          removeModal={() => setRemoveModal(false)}
          reload={props.reload}
          reloadApplication={props.reloadApplication}
        />
      ) : null}
      {accounts?.map((a) => {
        const isActive = a.id === props?.app?.active_bank_account;
        return (
          <div className={`account`} key={a.id}>
            <div className="a-logo">
              {a?.logo ? (
                <img src={`data:image/png;base64, ${a?.logo}`} alt={a?.name} />
              ) : (
                <IconBuildingBank />
              )}
            </div>
            <div className="a-info">
              <div className="a-row">
                <h4>{a.name}</h4>
                <div>
                  <IconBuildingBank />
                  {a.last_four}
                </div>
              </div>
              <div className="a-tag">
                {isActive ? (
                  <Tooltip title="Primary bank account being used for billing & payments.">
                    <Tag size="small" type="success">
                      Active
                    </Tag>
                  </Tooltip>
                ) : (
                  <Tooltip title="Bank account not currently used for billing or payments.">
                    <Tag size="small" type="neutral">
                      Inactive
                    </Tag>
                  </Tooltip>
                )}
              </div>
            </div>
            {!props.readOnly ? (
              <>
                {isActive ? (
                  <div className="a-actions">
                    <Button
                      type="nope"
                      size="tiny"
                      onClick={() => setRemoveModal(a)}
                    >
                      Remove
                    </Button>
                  </div>
                ) : (
                  <div className="a-actions">
                    <Button
                      type="blanco"
                      size="tiny"
                      onClick={() => setActiveModal(a)}
                    >
                      Set as active
                    </Button>
                    <Button
                      type="nope"
                      size="tiny"
                      onClick={() => setRemoveModal(a)}
                    >
                      Remove
                    </Button>
                  </div>
                )}
              </>
            ) : null}
          </div>
        );
      })}
    </div>
  );
}

export default Accounts;

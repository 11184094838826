import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { adminApplicationNotes } from "App/Admin/_graphql/queries/note";
import { getUsers } from "App/Admin/_graphql/queries/user";
import { Loading, TextArea, AvatarName, Select } from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { adminCreateNote } from "App/Admin/_graphql/mutations/note";
import moment from "moment";
import "./Notes.scss";

const Note = (props) => {
  const { note } = props;
  const { company, user } = note;
  return (
    <div className="application-note" id={"note-" + note.id}>
      <div className="note-header">
        <AvatarName tooltip={`${user?.name} (${company?.name})`}>
          {user?.name?.[0]}
        </AvatarName>
        <div className="note-user">{user?.name}</div>
        <span>{moment(note.createdAt * 1).format("MMMM Do, hh:mm a")}</span>
      </div>
      <div className="note-body">
        <p>{note.content}</p>
      </div>
    </div>
  );
};

function Notes(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [note, setNote] = useState();
  const [notes, setNotes] = useState([]);
  const [users, setUsers] = useState([]);
  const [email, setEmail] = useState([]);

  // Load Entries
  const fetchApplication = async () => {
    const notesData = await client.query({
      variables: { application_id: props?.applicationId },
      query: adminApplicationNotes,
    });
    setNotes(notesData?.data?.adminApplicationNotes || []);
    setLoading(false);
  };

  const fetchUsers = async () => {
    const usersData = await client.query({
      query: getUsers,
    });
    setUsers(usersData?.data?.getUsers || []);
  };

  useEffect(() => {
    fetchApplication();
    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createNote = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          application_id: props?.applicationId,
          content: note,
          user_id: email?.length > 0 ? email : null,
        },
        mutation: adminCreateNote,
      });
      fetchApplication();
      setNote(undefined);
      setEmail(undefined);
      setSaving(false);
    } catch (err) {
      message.error(getErrors(err) || "Error creating note, try again");
      setSaving(false);
    }
  };

  const createDates = (notes) => {
    let dates = [];
    notes.forEach((data) => {
      dates.push(moment(data.createdAt * 1).format("dddd, MMMM Do YYYY"));
    });
    return [...new Set(dates.reverse())].reverse();
  };

  let dateList = null;
  if (notes?.length > 0) {
    const dates = createDates(notes);
    dateList = dates.map((item, i) => {
      let notesList = notes.map((n) => {
        if (moment(n.createdAt * 1).format("dddd, MMMM Do YYYY") === item) {
          return <Note key={n.id} note={n} />;
        }
        return null;
      });
      return (
        <div key={"notegroup-" + i} date={item} className="date-section">
          <div className="note-date">
            <span>
              {item === moment().format("dddd, MMMM Do YYYY") ? "Today" : item}
            </span>
          </div>
          {notesList}
        </div>
      );
    });
  }

  return loading ? (
    <Loading />
  ) : (
    <div className="admin-application-notes">
      {!props.readOnly ? (
        <div className="note-compose">
          <TextArea
            disabled={saving}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            size="large"
            autoSize={true}
            placeholder="Start your note..."
          />
          <Button
            disabled={!note || note?.length < 1}
            loading={saving}
            type="primary"
            size="small"
            onClick={() => createNote()}
          >
            Add Note
          </Button>
          <Select
            value={email}
            onChange={(val) => setEmail(val)}
            size="average"
            popupClassName="pp-select-dropdown"
            placeholder="Select user to notify"
          >
            <Select.Option value="">Do not notify</Select.Option>
            {users?.map((u) => (
              <Select.Option key={u.id} value={u.id}>
                {u.name} ({u.email})
              </Select.Option>
            ))}
          </Select>
        </div>
      ) : null}
      <div className="note-content">
        {notes.length > 0 ? dateList : "No notes yet"}
      </div>
    </div>
  );
}

export default Notes;

import React from "react";
import { IconPhone } from "@tabler/icons-react";
import moment from "moment";

export default function Status(props) {
  const { activity, customer } = props;
  const user = activity?.user;
  // const company = activity?.company;
  const status = activity.call;
  let icon = <IconPhone />;

  return (
    <div className={"app-activity status-"}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {!status.incoming && (
          <React.Fragment>
            <b>{user.name}</b> called{" "}
            <b>{customer?.first_name + " " + customer?.last_name}</b> for{" "}
            {status.duration} second
            {status.duration === 1 ? "" : "s"}
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
}

//

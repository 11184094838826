import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { getCustomerTasks } from "App/Admin/_graphql/queries/task";
import { deleteTaskCustomer } from "App/Admin/_graphql/mutations/task";
import {
  IconPlus,
  IconAlignLeft,
  IconCircleCheckFilled,
} from "@tabler/icons-react";
import TaskCard from "./_components/TaskCard/TaskCard";
import CompleteTask from "./_components/CompleteTask/CompleteTask";
import { Button, Tooltip, Modal, message } from "@centrate-io/barn";
import { Loading } from "_styleguide";
import NoTasks from "./_components/NoTasks/NoTasks";
import ApplyWorkflow from "./_components/NoTasks/_components/ApplyWorkflow/ApplyWorkflow";
import CustomTask from "./_components/CustomTask/CustomTask";
import { getErrors } from "_helpers/api";
import "./Tasks.scss";

function Tasks(props) {
  const client = useApolloClient();
  const [tab, setTab] = useState("all");
  const [tasks, setTasks] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [loading, setLoading] = useState(true);
  const [completeModal, setCompleteModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [reset, setReset] = useState(true);
  const [showModal, setShowModal] = useState(false);

  // Load Tasks
  const fetchTasks = async () => {
    const queryData = await client.query({
      variables: {
        application_id: props.applicationId,
      },
      query: getCustomerTasks,
    });
    setTasks(queryData?.data?.getCustomerTasks || []);
    setLoading(false);
  };
  const removeCustomerTask = async (task_customer_id) => {
    Modal.confirm({
      title: "Are you sure you want remove this task?",
      okText: "Remove Task",
      content:
        "This action cannot be reversed and will remove the task. If needed, you can add a manual task to replace this one if done accidentally.",
      onOk: async () => {
        try {
          await client.mutate({
            variables: { task_customer_id },
            mutation: deleteTaskCustomer,
          });
          message.success(`Task has been removed`);
          fetchTasks();
        } catch (err) {
          message.error(getErrors(err) || "Error removing task, try again");
        }
      },
    });
  };

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.applicationId]);

  const expandAll = () => {
    if (expanded.length < tasks.length) {
      setExpanded(tasks.map((t) => t.id));
    } else {
      setExpanded([]);
    }
  };

  const toggle = (tab) => setTab(tab);

  const filteredTasks = tasks.filter((t) => {
    if (tab === "all") {
      return true;
    } else if (tab === "todo" && !t.completed_timestamp) {
      return true;
    } else if (tab === "done" && t.completed_timestamp) {
      return true;
    }
    return false;
  });

  const todoCount = tasks.filter((t) => !t.completed_timestamp)?.length;
  const doneCount = tasks.filter((t) => t.completed_timestamp)?.length;

  const manageTask = (template) => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setOpen(template);
    }, 1);
  };
  const showCustomTask = () => {
    setReset(true);
    setTimeout(() => {
      setReset(false);
      setOpen(true);
    }, 1);
  };

  return loading ? (
    <Loading />
  ) : (
    <>
      {tasks?.length <= 0 ? (
        <div className="admin-application-tasks">
          <NoTasks
            applicationId={props.applicationId}
            reload={fetchTasks}
            showCustomTask={showCustomTask}
            status={props.status}
            readOnly={props.readOnly}
          />
          {!reset ? (
            <CustomTask
              applicationId={props.applicationId}
              onClose={() => setOpen(false)}
              open={open}
              task={open}
              reload={fetchTasks}
            />
          ) : null}
        </div>
      ) : (
        <div className="admin-application-tasks">
          {showModal ? (
            <ApplyWorkflow
              applicationId={props.applicationId}
              reload={fetchTasks}
              removeModal={() => setShowModal(false)}
            />
          ) : null}
          {completeModal ? (
            <CompleteTask
              taskId={completeModal}
              reload={fetchTasks}
              removeModal={() => setCompleteModal(false)}
            />
          ) : null}
          {!reset ? (
            <CustomTask
              applicationId={props.applicationId}
              onClose={() => setOpen(false)}
              open={open}
              task={open}
              reload={fetchTasks}
            />
          ) : null}
          <div className="at-box">
            <div className="at-header">
              <div className="at-tabs">
                <div
                  className={tab === "all" ? "at-active" : ""}
                  onClick={() => toggle("all")}
                >
                  All <span className="tc-count">{tasks?.length}</span>
                </div>
                <div
                  className={tab === "todo" ? "at-active" : ""}
                  onClick={() => toggle("todo")}
                >
                  To do <span className="tc-count">{todoCount}</span>
                </div>
                <div
                  className={tab === "done" ? "at-active" : ""}
                  onClick={() => toggle("done")}
                >
                  <IconCircleCheckFilled />
                  Done <span className="tc-count">{doneCount}</span>
                </div>
              </div>
              <div className="at-actions">
                <Tooltip title="Expand all">
                  <Button
                    type="blanco"
                    className="boxed"
                    size="small"
                    onClick={expandAll}
                  >
                    <IconAlignLeft />
                  </Button>
                </Tooltip>
                {!props.readOnly ? (
                  <Button
                    type="secondary"
                    className="boxed"
                    size="small"
                    onClick={() => setShowModal(true)}
                  >
                    <IconPlus /> Workflow
                  </Button>
                ) : null}
                {!props.readOnly ? (
                  <Button
                    type="primary"
                    className="boxed"
                    size="small"
                    onClick={showCustomTask}
                  >
                    <IconPlus /> Add Task
                  </Button>
                ) : null}
              </div>
            </div>
            <div className="at-list">
              {filteredTasks.map((t) => (
                <TaskCard
                  key={t.id}
                  task={t}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  setCompleteModal={setCompleteModal}
                  removeCustomerTask={removeCustomerTask}
                  manageTask={manageTask}
                  readOnly={props.readOnly}
                  reload={fetchTasks}
                />
              ))}
              {filteredTasks?.length <= 0 ? (
                <div className="no-tasks">
                  {tab === "all" ? "No tasks available" : null}
                  {tab === "todo" ? "No tasks to do" : null}
                  {tab === "done" ? "No tasks completed yet" : null}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Tasks;

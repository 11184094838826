import { Text, Button } from "@centrate-io/barn";
import {
  IconUser,
  IconCalendar,
  IconIdBadge2,
  IconBuilding,
  IconAddressBook,
  IconMail,
  IconPhone,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import moment from "moment";

export const getColumns = (myId, myRole, myCompanyId, masq) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconUser />
          User
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{name || "New User"}</b>
            {data?.role !== "user-invite" && data?.id === myId && (
              <Tag type="neutral" size="small">
                You
              </Tag>
            )}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            ID: <i>{data.id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Contact
        </div>
      ),
      dataIndex: "contact",
      key: "contact",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconMail />
            <span>{data.email || "n/a"}</span>
          </Text>
          <Text className="doc-name">
            <IconPhone />
            <span>{data.phone || "n/a"}</span>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconBuilding />
          Company
        </div>
      ),
      dataIndex: "company",
      key: "company",
      width: 240,
      render: (name, data) =>
        data.companies?.map((c) => (
          <div>
            <Text className="doc-name">
              <IconBuilding />
              <b>{c?.company?.name}</b>
              {c?.company?.role === "company-invite" && (
                <Tag type="success" size="small">
                  Invite pending
                </Tag>
              )}
              {c?.company?.parent_company_id ? (
                <Tag type="neutral" size="small">
                  Partner
                </Tag>
              ) : null}
            </Text>
            <Text className="helper" style={{ paddingLeft: "20px" }}>
              ID: <i>{c?.company?.id}</i>
            </Text>
          </div>
        )),
    },
    {
      title: (
        <div className="column-name">
          <IconIdBadge2 />
          Role
        </div>
      ),
      dataIndex: "role",
      key: "role",
      width: 120,
      render: (invited_date, data) => (
        <div className="role-column">
          {data?.role === "user-invite" && (
            <Tag type="success" size="small">
              Invite pending
            </Tag>
          )}
          {data?.role !== "user-invite" && (
            <Tag type={data?.role} size="role">
              {data?.role}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Joined/Invited
        </div>
      ),
      width: 120,
      dataIndex: "joined_date",
      key: "joined_date",
      render: (joined_date, data) => (
        <Text>
          {data.joined_date
            ? moment(parseInt(data.joined_date)).format("M/D/YYYY")
            : moment(parseInt(data.invited_date)).format("M/D/YYYY")}
        </Text>
      ),
    },

    {
      title: <div className="column-name"></div>,
      dataIndex: "masquerade",
      key: "masquerade",
      hidden: myRole !== "super",
      width: 150,
      align: "right",
      render: (masquerade, data) => {
        const companyData = data?.companies?.[0]?.company;
        return data.id !== myId && companyData ? (
          <Button
            className="boxed"
            type="secondary"
            onClick={() => masq(data.id, companyData?.id)}
            size="small"
          >
            Login as User
          </Button>
        ) : (
          ""
        );
      },
    },
  ];
};

import { getTemplateVariables } from "App/Admin/_graphql/queries/task";

export const fetchTemplates = async (client, type, application_id) => {
  const queryData = await client.query({
    query: getTemplateVariables,
    variables: {
      type: type || null,
      application_id: application_id,
    },
  });
  return {
    templates: queryData?.data?.getTemplateVariables?.templates || [],
    customer: queryData?.data?.getTemplateVariables?.customer || {},
  };
};

const organizeMap = (customer, user, company) => {
  let newMap = {};
  Object.keys(customer).forEach((cKey) => {
    newMap[`customer.${cKey}`] = customer[cKey];
  });
  Object.keys(user).forEach((uKey) => {
    newMap[`employee.${uKey}`] = user[uKey];
  });
  Object.keys(company).forEach((cKey) => {
    newMap[`company.${cKey}`] = company[cKey];
  });
  return newMap;
};

export const replaceData = (template, customer, user) => {
  let instructions = template.instructions + "";
  const vars = template.variables || [];
  const dataMap = organizeMap(customer, user, user.company);
  vars.forEach((v) => {
    const variableToReplace = `{{${v.variable}}}`;
    const replaceWith = dataMap[v.mapping];
    const regex = new RegExp(variableToReplace, "g");
    instructions = instructions.replace(regex, replaceWith);
  });
  return instructions;
};

import React from "react";
import { IconMail } from "@tabler/icons-react";
import moment from "moment";

export default function Status(props) {
  const { activity } = props;

  const status = activity.email;
  let icon = <IconMail />;

  return (
    <div className={"app-activity status-"}>
      <div className="act-icon">{icon}</div>
      <div className="descript">
        {!status.incoming && (
          <React.Fragment>
            Email sent from <b>{status.from_email}</b> to{" "}
            <b>{status.to_email}</b> with the subject <b>{status.subject}</b>
          </React.Fragment>
        )}
        {status.incoming && (
          <React.Fragment>
            Incoming email from <b>{status.from_email}</b> to{" "}
            <b>{status.to_email}</b> with the subject <b>{status.subject}</b>
          </React.Fragment>
        )}
        <div className="act-tstamp">
          &nbsp;&nbsp;&bull;&nbsp;&nbsp;
          {moment(activity.createdAt * 1).format("MMM D @ h:mm a")}
        </div>
        &nbsp;&nbsp;&bull;&nbsp;&nbsp;&nbsp;
      </div>
    </div>
  );
}

//

import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown, Flex } from "antd";
import { IconSelector } from "@tabler/icons-react";
import { logOut } from "_helpers/auth";
import { Tag } from "_styleguide";
import moment from "moment";
import { useApolloClient } from "@apollo/client";
import { switchAccount } from "_graphql/mutations/user";
import "./Company.scss";

// const ROLES = {
//   admin: "Admin",
//   manager: "Manager",
//   owner: "Owner",
//   rep: "Sales Rep",
//   user: "Sales Rep",
//   super: "Super Admin",
// };

function Company(props) {
  const client = useApolloClient();
  const navigate = useNavigate();
  const { user, collapsed } = props;
  const { company, parent_company, companies } = user;

  const switchUser = async (user_id, company_id, companyName) => {
    const queryData = await client.mutate({
      variables: { user_id: user_id, company_id: company_id },
      mutation: switchAccount,
    });
    const token = queryData?.data?.switchAccount;
    if (token && props.login) {
      props.login(token);
      navigate("/", { replace: true });
      window.location.reload();
    }
  };

  const otherCompanies = companies?.filter((c) => c.id !== company.id);

  const items = [
    {
      label: (
        <div className="drop-user">
          <h5>
            {user?.name}
            <Tag type={user?.role} size="small">
              {user?.role}
            </Tag>
          </h5>
          <p>{user?.email}</p>
          {parent_company ? (
            <p style={{ marginTop: "4px" }}>
              Partner for {parent_company?.name}
            </p>
          ) : null}
        </div>
      ),
      className: "drop-user-container",
    },
    { type: "divider" },
    {
      label: (
        <div className="switch-company">
          <h5>Switch Account</h5>
          {otherCompanies?.map((c) => (
            <div
              key={`switch-${c.id}`}
              className="company-select"
              onClick={() => switchUser(user.id, c.id, c.name)}
            >
              <h5>{c.name}</h5>
              <p>
                <i>{c.role}</i> - Joined{" "}
                {moment(c.joined_date).format("M/D/YYYY")}
              </p>
            </div>
          ))}
        </div>
      ),
      className: `switch-company-container${otherCompanies?.length <= 0 ? " no-show" : ""}`,
    },
    {
      type: "divider",
      className: otherCompanies?.length <= 0 ? "no-show" : "",
    },
    {
      label: <span>Sign out</span>,
      onClick: logOut,
    },
  ];

  return (
    <Flex className="company-container" vertical={true}>
      <Dropdown
        className={`company-drop ${collapsed ? "drop-collapsed" : ""}`}
        menu={{
          items,
        }}
        trigger={["click"]}
        overlayClassName="company-drop-drop"
      >
        <Flex>
          {company?.logo ? (
            <img className="top-logo" src={company.logo} alt="Logo" />
          ) : (
            <Flex align="center" justify="center">
              {company?.name?.[0] || "C"}
            </Flex>
          )}

          {!collapsed && <h3>{company?.name}</h3>}
          <IconSelector size="18" />
        </Flex>
      </Dropdown>
    </Flex>
  );
}

export default Company;

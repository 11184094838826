import { Text } from "@centrate-io/barn";
import {
  IconSignature,
  IconScale,
  IconCalendar,
  IconSubtask,
} from "@tabler/icons-react";
import { formatDuration } from "_assets/js/helpers";
import { CompanyAvatar, UserAvatar } from "_styleguide";
import { statusMap } from "_constants/twilio";
import moment from "moment";

export const getColumns = (userData, appName, userRole, partner, readOnly) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconSignature />
          Call Type
        </div>
      ),
      dataIndex: "order",
      key: "order",
      sorter: true,
      width: 240,
      render: (order, data) => (
        <div className="call-desc">
          {data.incoming_call ? (
            <UserAvatar name={data?.user?.name} />
          ) : (
            <CompanyAvatar user={userData} />
          )}
          <div>
            <Text className="call-name">
              {data.incoming_call ? "Incoming Call" : "Outgoing Call"}
            </Text>
            <Text className="helper">
              To: {data.incoming_call ? data?.user?.name : appName}
            </Text>
          </div>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconSubtask />
          Task
        </div>
      ),
      dataIndex: "task",
      key: "task",
      sorter: true,
      render: (task, data) => (
        <Text className="call-task">{data?.task?.title || "----"}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Status
        </div>
      ),
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (status) => (
        <Text className="call-task">{statusMap[status]}</Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconScale />
          Duration
        </div>
      ),
      dataIndex: "duration",
      key: "duration",
      sorter: true,
      render: (duration) => <Text>{formatDuration(duration || 0)}</Text>,
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Last Attempt
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      sorter: true,
      width: 220,
      render: (createdAt) => (
        <Text>
          {createdAt ? (
            moment(parseInt(createdAt)).format("MMM D, YYYY - h:mm A")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
  ];
};

import React, { Component } from "react";
import {
  IconPhone,
  IconSubtask,
  IconMail,
  IconMessage,
  IconCoin,
  IconReport,
  IconFileText,
  IconMailbox,
} from "@tabler/icons-react";
import "./TaskBadge.scss";

class TaskBadge extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-task-badge " + (props.className || "");

    if (props.type) {
      props.className += " pp-task-badge-" + props.type;
    }

    if (props.size) {
      props.className += " pp-task-badge-" + props.size;
    }

    let name = "",
      icon = null;
    if (props.type === "call") {
      name = "Call";
      icon = <IconPhone />;
    } else if (props.type === "email") {
      name = "Email";
      icon = <IconMail />;
    } else if (props.type === "text") {
      name = "Text Message";
      icon = <IconMessage />;
    } else if (props.type === "general") {
      name = "General";
      icon = <IconSubtask />;
    } else if (props.type === "payments") {
      name = "Payments";
      icon = <IconCoin />;
    } else if (props.type === "credit") {
      name = "Credit";
      icon = <IconReport />;
    } else if (props.type === "documents") {
      name = "Documents";
      icon = <IconFileText />;
    } else if (props.type === "mail") {
      name = "Mail";
      icon = <IconMailbox />;
    }

    let type = "icon";
    if (props.full) type = "full";
    props.className += " pp-task-badge-" + type;

    return type === "icon" ? (
      <div {...props}>{icon}</div>
    ) : (
      <div {...props}>
        {icon}
        {name}
      </div>
    );
  }
}

export default TaskBadge;

import { CompanyAvatar, UserAvatar } from "_styleguide";
import { stripHTML } from "_assets/js/helpers";
import moment from "moment";
import "./EmailCollapsed.scss";

function EmailCollapsed(props) {
  const { c, toggleOpen, userData } = props;
  return (
    <div className="convo-collapsed" onClick={() => toggleOpen(c.id)}>
      {c?.user ? (
        <CompanyAvatar user={userData} />
      ) : (
        <UserAvatar name={c?.from?.name || c?.from?.email} />
      )}
      <div className="convo-info">
        {c?.from?.name ? (
          <h5>{`${c?.from?.name} <${c?.from?.email}>`}</h5>
        ) : (
          <h5>{c?.from?.email}</h5>
        )}
        <p className="convo-preview">{stripHTML(c?.body || "")}</p>
      </div>
      <div className="convo-date">
        {moment(parseInt(c.createdAt)).format("ddd M/D/YY h:mm A")}
      </div>
    </div>
  );
}

export default EmailCollapsed;

import React from "react";
import Google from "_assets/logos/google.svg";
import { IconPlugConnected } from "@tabler/icons-react";
import { Button, message } from "@centrate-io/barn";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const GmailConnect = (props) => {
  const login = useGoogleLogin({
    flow: "auth-code", // Specify the auth-code flow to get authorization code
    onSuccess: async ({ code }) => {
      // Send the authorization code to your backend
      await axios.post(
        `${process.env.REACT_APP_CALLS_API_PATH}/auth/google/callback`,
        {
          code,
        },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
        },
      );
      if (props.reload) await props.reload();
      message.success("Google Gmail is now connected");
    },
    onError: (error) => {
      message.error("Google integration connection failed: " + error);
    },
    scope: "https://www.googleapis.com/auth/gmail.send", // Specify the required scopes
  });
  return (
    <Button className="boxed" type="primary" onClick={login}>
      <IconPlugConnected /> Connect Gmail
    </Button>
  );
};

const Gmail = {
  type: "gmail",
  category: "email",
  logo: Google,
  name: "Google Gmail",
  description:
    "Login to your Gmail account to enable sending emails and completing email tasks through PayPath.",
  Connect: GmailConnect,
};

export default Gmail;

import { Text } from "@centrate-io/barn";
import {
  IconUser,
  IconCalendar,
  IconIdBadge2,
  IconCircleX,
  IconBuilding,
  IconMail,
  IconPhone,
  IconAddressBook,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import { TableActions } from "_styleguide";
import moment from "moment";

const access = {
  super: ["super", "admin", "manager", "user", "removed"],
  admin: ["admin", "manager", "user", "removed"],
  manager: ["manager", "user", "removed"],
  user: [],
};

export const getColumns = (
  myId,
  myRole,
  myCompanyId,
  setRoleModal,
  removeUser,
  readOnly,
) => {
  const myAccess = access[myRole];
  return [
    {
      title: (
        <div className="column-name">
          <IconUser />
          User
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{name || "New User"}</b>
            {data?.role !== "user-invite" && data?.id === myId && (
              <Tag type="primary" size="small">
                You
              </Tag>
            )}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            ID: <i>{data.short_id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconBuilding />
          Company
        </div>
      ),
      dataIndex: "company",
      key: "company",
      width: 240,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconBuilding />
            <b>{data?.company?.name}</b>
            {data?.company?.role === "company-invite" && (
              <Tag type="success" size="small">
                Invite pending
              </Tag>
            )}
            {data?.company?.role === "company" &&
            data?.company?.id !== myCompanyId ? (
              <Tag type="neutral" size="small">
                Partner
              </Tag>
            ) : null}
          </Text>
          {/*          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data?.company?.short_id}</i>
          </Text>*/}
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconAddressBook />
          Contact
        </div>
      ),
      dataIndex: "contact",
      key: "contact",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconMail />
            <span>{data.email || "n/a"}</span>
          </Text>
          <Text className="doc-name">
            <IconPhone />
            <span>{data.phone || "n/a"}</span>
          </Text>
        </div>
      ),
    },

    {
      title: (
        <div className="column-name">
          <IconIdBadge2 />
          Role
        </div>
      ),
      dataIndex: "role",
      key: "role",
      width: 120,
      render: (invited_date, data) => (
        <div className="role-column">
          {data?.role === "user-invite" && (
            <Tag type="success" size="small">
              Invite pending
            </Tag>
          )}
          {data?.role !== "user-invite" && (
            <Tag type={data?.role} size="role">
              {data?.role}
            </Tag>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Joined/Invited
        </div>
      ),
      dataIndex: "joined_date",
      key: "joined_date",
      render: (joined_date, data) => (
        <Text>
          {data.joined_date
            ? `Joined ${moment(parseInt(data.joined_date)).format("M/D/YYYY")}`
            : `Invited ${moment(parseInt(data.invited_date)).format("M/D/YYYY")}`}
        </Text>
      ),
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      sorter: false,
      width: 60,
      fixed: "right",
      hidden: readOnly,
      render: (actions, data) => {
        const items = [
          {
            label: (
              <span>
                <IconIdBadge2 /> Change Role
              </span>
            ),
            onClick: () => setRoleModal(data),
          },
        ];
        if (data.role !== "removed") {
          items.push({
            label: (
              <span className="bad">
                <IconCircleX /> Remove Access
              </span>
            ),
            onClick: () => removeUser(data.id, data.name),
          });
        }
        return data?.id !== myId && myAccess?.includes(data.role) ? (
          <TableActions items={items} />
        ) : (
          <TableActions items={items} disabled />
        );
      },
    },
  ];
};

import React from "react";
import { Switch } from "antd";
import "./Toggle.scss";

const Toggle = (incomingProps) => {
  const props = { ...incomingProps };
  props.className = "pp-toggle " + (props.className || "");

  return <Switch {...props} />;
};

export default Toggle;

import { useState, useEffect, useRef } from "react";
import { TextArea, Empty } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import { useNavigate } from "react-router-dom";
import { getTextMessages } from "_graphql/queries/textmessage";
import { createTextMessage } from "_graphql/mutations/textmessage";
import { Button, message } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconPuzzle } from "@tabler/icons-react";
import { useIntegrations } from "_contexts/useIntegrations";
import moment from "moment";
import io from "socket.io-client";
import "./Texts.scss";

function Texts(props) {
  const callsClient = CallsClient;
  const navigate = useNavigate();
  const { integrations } = useIntegrations();
  const [texts, setTexts] = useState([]);
  const [text, setText] = useState(null);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(true);
  const endOfListRef = useRef(null);

  // Load Text Messages
  const fetchTexts = async () => {
    const queryData = await callsClient.query({
      variables: {
        application_id: props.applicationId,
      },
      query: getTextMessages,
    });
    setTexts(queryData?.data?.getTextMessages?.reverse() || []);
    setLoading(false);
    setTimeout(() => {
      if (endOfListRef.current) {
        endOfListRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 0);
  };

  const sendText = async () => {
    setSending(true);
    try {
      await callsClient.mutate({
        variables: {
          application_id: props.applicationId,
          task_id: null,
          content: text,
        },
        mutation: createTextMessage,
      });
      message.success("Message sent");
      fetchTexts();
      setText(null);
      setSending(false);
    } catch (err) {
      message.error(getErrors(err) || "Error sending message, try again");
      setSending(false);
    }
  };

  useEffect(() => {
    fetchTexts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Setup IO
  useEffect(() => {
    if (props.applicationId) {
      // Initialize the socket inside the component
      const newSocket = io(process.env.REACT_APP_CALLS_API_PATH, {
        withCredentials: true,
      }); // Replace with your server's URL

      // Listen for call status updates from the server
      newSocket.on(`incomingText-${props.applicationId}`, fetchTexts);

      // Clean up the socket connection when the component unmounts
      return () => {
        newSocket.off(`incomingText-${props.applicationId}`);
        newSocket.disconnect();
      };
    }
  }, []);

  if (texts?.length <= 0 && !integrations.text) {
    return (
      <Empty
        title="No Texting Integration"
        description="Setup your Twilio Text integration to send and receive text messages."
        action="Setup Integration"
        actionIcon={<IconPuzzle />}
        onClick={() => navigate("/settings/integrations")}
      />
    );
  }

  return (
    <div className="admin-application-texts">
      <div className="text-compose">
        <TextArea
          disabled={sending}
          value={text}
          onChange={(e) => setText(e.target.value)}
          size="large"
          autoSize={true}
          placeholder="Message..."
        />
        <Button
          className="send-btn"
          disabled={!text || text?.length < 1}
          loading={sending}
          type="primary"
          size="small"
          onClick={() => sendText()}
        >
          Send
        </Button>
      </div>
      {texts?.map((text, i) => {
        let className = "message";
        let from = text.user?.name;
        if (text.outgoing) {
          className += " right";
        } else {
          from =
            text.application?.first_name + " " + text.application?.last_name;
          className += " left";
        }
        return (
          <div key={text.id} className={className}>
            <div>
              {from}&nbsp;&nbsp;&bull;&nbsp;&nbsp;
              {moment(parseInt(text.createdAt)).format("M/D/YY h:mm A")}
            </div>
            {text.content || " "}
          </div>
        );
      })}
      {texts?.length <= 0 && !loading ? (
        <div className="no-text">No text history yet</div>
      ) : null}
      <div ref={endOfListRef}></div>
    </div>
  );
}

export default Texts;

import React from "react";
import TwilioSVG from "_assets/logos/twilio.svg";
import { IconMailForward } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";

const TwilioConnect = (props) => {
  const handleLogin = async () => {
    const recipient = "engineering@paypath.ai";
    const subject = "Help setting up Twilio Messaging in PayPath";
    const body =
      "I am reaching out because I need to setup Twilio Messaging in PayPath so I can start sending text messages";

    window.location.href = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  };

  return (
    <Button className="boxed" type="primary" onClick={handleLogin}>
      <IconMailForward /> Contact Us
    </Button>
  );
};

const TwilioSMS = {
  type: "twilio_sms",
  category: "text",
  company_only: true,
  contact_only: true,
  logo: TwilioSVG,
  name: "Twilio Messaging",
  description:
    "Contact us to submit for a verified number to send sms text messages from.",
  Connect: TwilioConnect,
};

export default TwilioSMS;

import React, { useState, useEffect } from "react";
import { Table, Search } from "_styleguide";
import { useApolloClient } from "@apollo/client";
import { ownerUsers } from "App/Owner/_graphql/queries/user";
import { getColumns } from "./_data/Columns";
import { masqueradeUser } from "App/Admin/_graphql/mutations/user";
import { IconUsers } from "@tabler/icons-react";
import "./Users.scss";

function Users(props) {
  const client = useApolloClient();
  const [state, setState] = useState({
    loading: true,
    data: undefined,
    pagination: { pageSize: 10, showSizeChanger: false },
  });
  const [search, setSearch] = useState();

  // Load Payments
  const fetchDocuments = async () => {
    const queryData = await client.query({
      query: ownerUsers,
    });
    setState({
      loading: false,
      data: queryData?.data?.ownerUsers || [],
    });
    props?.setCurrentCount(queryData?.data?.ownerUsers?.length || 0);
  };

  const masq = async (user_id, company_id) => {
    const queryData = await client.mutate({
      variables: { user_id: user_id, company_id: company_id },
      mutation: masqueradeUser,
    });
    const token = queryData?.data?.masqueradeUser;
    if (token && props.login) {
      props.login(token);
    }
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "User Management",
      icon: <IconUsers />,
      description: "Manage your current users and seats available",
    });
    fetchDocuments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let tableData = (state?.data || []).filter((u) => {
    if ((search?.length || 0) <= 0) return true;
    const nameSearch = u.name?.toLowerCase();
    const idSearch = u.short_id?.toLowerCase();
    return nameSearch.includes(search) || idSearch.includes(search);
  });
  const columns = getColumns(
    props?.user?.id,
    props?.user?.role,
    props?.user?.company?.id,
    masq,
  );

  return (
    <div className="settings-users">
      <div className="admin-search admin-button">
        <div className="ms-filters">
          <Search
            placeholder={`Search ${state.data?.length || ""} users...`}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            style={{ width: 304 }}
          />
        </div>
      </div>
      <div className="admin-table application-table">
        <Table
          columns={columns}
          loading={state.loading}
          pagination={state.pagination}
          rowKey={(record) => record.id}
          dataSource={tableData}
          showSorterTooltip={false}
          scroll={{
            y: "calc(100vh - 215px)",
            x: "max-content",
          }}
          locale={{
            emptyText: state.loading ? "Loading users..." : "No users found",
          }}
        />
      </div>
      {state?.data?.length > 0 && (
        <div className="admin-pagination">
          {/*Showing {startResults}-{endResults} of {state?.pagination?.total || 0}{" "}*/}
        </div>
      )}
    </div>
  );
}

export default Users;

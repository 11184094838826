import { IconX } from "@tabler/icons-react";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import Search from "../Search/Search";
import Missing from "../Missing/Missing";
import "./Mail.scss";

function Mail(props) {
  const { integrations } = useIntegrations();
  const { closeCall, activeData } = useBar();

  if (!integrations?.mail) {
    return <Missing type="mail" />;
  }

  return !activeData?.customer ? (
    <Search type="mail" />
  ) : (
    <div className="bar-mail">
      <h4>Mail goes here</h4>
      <div className="dialer-close" onClick={closeCall}>
        <IconX />
      </div>
    </div>
  );
}

export default Mail;

import { gql } from "@apollo/client";

export const createEmail = gql`
  mutation createEmail(
    $application_id: String!
    $task_id: String
    $email_to: [String]
    $email_from: String!
    $subject: String!
    $body: String!
    $is_html: Boolean!
    $ext_email_id: String
    $ext_thread_id: String
    $thread_id: String
  ) {
    createEmail(
      application_id: $application_id
      task_id: $task_id
      email_to: $email_to
      email_from: $email_from
      subject: $subject
      body: $body
      is_html: $is_html
      ext_email_id: $ext_email_id
      ext_thread_id: $ext_thread_id
      thread_id: $thread_id
    )
  }
`;

import { gql } from "@apollo/client";

export const disconnectIntegration = gql`
  mutation disconnectIntegration($integration_id: String!) {
    disconnectIntegration(integration_id: $integration_id)
  }
`;

export const toggleIntegration = gql`
  mutation toggleIntegration($integration_id: String!) {
    toggleIntegration(integration_id: $integration_id)
  }
`;

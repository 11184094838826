import React, { useState } from "react";
import { Flex } from "antd";
import "./DialPad.scss";

function DialPad(props) {
  const [pressed, setPressed] = useState("");
  const u = (num) => {
    if (props.call && num?.toString()) {
      props.call.sendDigits(num?.toString());
      setPressed(pressed + num?.toString());
    }
  };

  return (
    <div className="pp-dialpad">
      <div className="keypad">{pressed || <span>&nbsp;</span>}</div>
      <Flex>
        <div onClick={() => u(1)}>1</div>
        <div onClick={() => u(2)}>2</div>
        <div onClick={() => u(3)}>3</div>
      </Flex>
      <Flex>
        <div onClick={() => u(4)}>4</div>
        <div onClick={() => u(5)}>5</div>
        <div onClick={() => u(6)}>6</div>
      </Flex>
      <Flex>
        <div onClick={() => u(7)}>7</div>
        <div onClick={() => u(8)}>8</div>
        <div onClick={() => u(9)}>9</div>
      </Flex>
      <Flex>
        <div onClick={() => u("*")}>*</div>
        <div onClick={() => u(0)}>0</div>
        <div onClick={() => u("#")}>#</div>
      </Flex>
    </div>
  );
}

export default DialPad;

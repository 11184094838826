import { gql } from "@apollo/client";

export const signupUser = gql`
  mutation signupUser(
    $user_id: ID!
    $company_id: ID!
    $name: String!
    $email: String!
    $phone: String!
    $password: String!
  ) {
    signupUser(
      user_id: $user_id
      company_id: $company_id
      name: $name
      email: $email
      phone: $phone
      password: $password
    )
  }
`;

export const switchAccount = gql`
  mutation switchAccount($user_id: String!, $company_id: String!) {
    switchAccount(user_id: $user_id, company_id: $company_id)
  }
`;

export const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

export const changePassword = gql`
  mutation changePassword($password: String!, $token: String!) {
    changePassword(password: $password, token: $token)
  }
`;

export const sendVerification = gql`
  mutation sendVerification($user_id: ID!, $channel: String) {
    sendVerification(user_id: $user_id, channel: $channel)
  }
`;

export const checkVerification = gql`
  mutation checkVerification($code: String!, $channel: String) {
    checkVerification(code: $code, channel: $channel)
  }
`;

import React from "react";
import {
  IconClock,
  IconRotateClockwise2,
  IconCheck,
  IconPhone,
  IconAlignLeft,
  IconMessage,
  IconMail,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import { TaskBadge, Tag, Flex, NameLogo } from "_styleguide";
import { Button, Tooltip } from "@centrate-io/barn";
import moment from "moment";
import { useBar } from "_contexts/useBar";
import { useIntegrations } from "_contexts/useIntegrations";
import { stripHTML } from "_assets/js/helpers";
import "./TaskCard.scss";

function TaskCard(props) {
  const { setupCall, setupText, setupEmail } = useBar();
  const { integrations } = useIntegrations();
  const { task } = props;

  let timing = null;
  let completed = false;
  const format = "ddd MMM Do, YYYY";
  if (task.completed_timestamp) {
    timing = moment(parseInt(task.completed_timestamp)).format("MMM Do, YYYY");
    completed = true;
  } else {
    const dueDays = task.due_days ? Math.abs(task.due_days) : null;
    if (task.due_days !== null && dueDays < 60) {
      if (task.due_days === 0) {
        timing = "Due now";
      } else if (task.due_days < 0) {
        timing = `Due ${dueDays} day${dueDays === 1 ? "" : "s"} ago`;
      } else if (task.due_days > 0) {
        timing = `Due in ${dueDays} day${dueDays === 1 ? "" : "s"}`;
      }
    } else {
      timing = "Due on " + moment(parseInt(task.due_timestamp)).format(format);
    }
  }

  const expandTask = () => {
    const expanded = [...props.expanded];
    if (expanded.includes(task.id)) {
      props.setExpanded(expanded.filter((t) => t !== task.id));
    } else {
      expanded.push(task.id);
      props.setExpanded(expanded);
    }
  };

  const isExpanded = props.expanded?.includes(task.id);

  const appName =
    task?.application?.first_name + " " + task?.application?.last_name;

  const canCall = integrations?.call && task?.type === "call" ? true : false;
  const canText = integrations?.text && task?.type === "text" ? true : false;
  const canEmail = integrations?.email && task?.type === "email" ? true : false;

  const showComplete =
    !task.completed_timestamp &&
    (task.type === "general" ||
      (task.type === "call" && !integrations?.call) ||
      (task.type === "text" && !integrations?.call) ||
      (task.type === "email" && !integrations?.call));

  const actionTask = { ...task, reload: props.reload };

  return (
    <div className={`my-task-card${completed ? " done" : ""}`}>
      {showComplete ? ( // Need tdo fix
        <Button
          type="primary"
          className="boxed tc-complete"
          size="small"
          onClick={() => props.setCompleteModal(task.id)}
        >
          <IconCheck /> Complete
        </Button>
      ) : null}
      {!task.completed_timestamp && canCall ? (
        <>
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => {
              props.closeTasks();
              setupCall(task.application, actionTask, true);
            }}
          >
            <IconPhone /> Call
          </Button>
        </>
      ) : null}
      {!task.completed_timestamp && canText ? (
        <>
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => {
              props.closeTasks();
              setupText(task.application, actionTask, true);
            }}
          >
            <IconMessage /> Text
          </Button>
        </>
      ) : null}

      {!task.completed_timestamp && canEmail ? (
        <>
          <Button
            type="primary"
            className="boxed tc-complete"
            size="small"
            onClick={() => {
              props.closeTasks();
              setupEmail(task.application, actionTask, true);
            }}
          >
            <IconMail /> Email
          </Button>
        </>
      ) : null}

      <Flex gap={16}>
        <div className="tc-content">
          <div className="tc-header">
            <h4>
              {completed && !task.skipped ? <IconCheck /> : null}
              {task.skipped ? <IconRotateClockwise2 /> : null}
              {task.title}
            </h4>
            <Tooltip title="Expand">
              <Button type="secondary" className="boxed" onClick={expandTask}>
                <IconAlignLeft />
              </Button>
            </Tooltip>
          </div>
          {isExpanded ? (
            <p>
              {stripHTML(
                task.template?.instructions || task.instructions || "",
              )}
            </p>
          ) : null}
          {isExpanded && task.note ? (
            <p>
              <b>Note:</b>&nbsp;&nbsp;{task.note}
            </p>
          ) : null}
          <div className="tc-info">
            <TaskBadge full="true" type={task.type} />
            {task.completed_timestamp ? (
              <Tooltip title={task.skipped ? "Skipped" : "Completed"}>
                <Tag size="small">
                  {task.skipped ? <IconRotateClockwise2 /> : <IconCheck />}
                  {timing}
                </Tag>
              </Tooltip>
            ) : (
              <Tag size="small">
                <IconClock />
                {timing}
              </Tag>
            )}
            <Link to={`/customer/${task?.application?.short_id}/tasks`}>
              <Tag size="small">
                <NameLogo name={appName} size="tiny" />
                {appName}
              </Tag>
            </Link>
          </div>
        </div>
      </Flex>
    </div>
  );
}

export default TaskCard;

import CustomerSearch from "../../_shared/CustomerSearch/CustomerSearch";
import TaskSearch from "../../_shared/TaskSearch/TaskSearch";
import { IconX } from "@tabler/icons-react";
import { useBar } from "_contexts/useBar";
import "./Search.scss";

function Search(props) {
  const { closeCall, setupCall, setupText, setupEmail } = useBar();

  const onSelect = async (customer, task) => {
    if (props.type === "call") {
      await setupCall(customer, task || null, true);
    } else if (props.type === "text") {
      await setupText(customer, task || null, true);
    } else if (props.type === "email") {
      await setupEmail(customer, task || null, true);
    }
  };

  return (
    <div className="bar-search">
      <h4>Select a customer or task below to {props.type}:</h4>
      <div>
        <CustomerSearch onSelect={onSelect} />
      </div>
      <div className="or-bar">
        <div />
        <span>or</span>
        <div />
      </div>
      <div>
        <TaskSearch onSelect={onSelect} type={props.type} />
      </div>
      <div className="dialer-close" onClick={closeCall}>
        <IconX />
      </div>
    </div>
  );
}

export default Search;

import React, { useState, useEffect } from "react";
import { Button } from "@centrate-io/barn";
import { getCrumbs } from "../_helpers/crumbs";
import Texting from "./_modals/Texting/Texting";
import Calling from "./_modals/Calling/Calling";
import Gmail from "./_modals/Gmail/Gmail";
import Docusign from "./_modals/Docusign/Docusign";
import Templates from "./_modals/Templates/Templates";
import "./Playground.scss";

function Playground(props) {
  const [textModal, setTextModal] = useState(false);
  const [callModal, setCallModal] = useState(false);
  const [gmailModal, setGmailModal] = useState(false);
  const [docusignModal, setDocusignModal] = useState(false);
  const [templatesModal, setTemplatesModal] = useState(false);

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs(getCrumbs("playground"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="admin-playground">
      {textModal ? <Texting removeModal={() => setTextModal(false)} /> : null}
      {callModal ? <Calling removeModal={() => setCallModal(false)} /> : null}
      {gmailModal ? <Gmail removeModal={() => setGmailModal(false)} /> : null}
      {docusignModal ? (
        <Docusign removeModal={() => setDocusignModal(false)} />
      ) : null}
      {templatesModal ? (
        <Templates removeModal={() => setTemplatesModal(false)} />
      ) : null}
      <Button
        className="boxed"
        type="secondary"
        onClick={() => setTextModal(true)}
      >
        Text Messages
      </Button>
      <Button
        className="boxed"
        type="secondary"
        onClick={() => setCallModal(true)}
      >
        Calling
      </Button>
      <Button
        className="boxed"
        type="secondary"
        onClick={() => setDocusignModal(true)}
      >
        Docusign
      </Button>
      <Button
        className="boxed"
        type="secondary"
        onClick={() => setGmailModal(true)}
      >
        Gmail
      </Button>
      <Button
        className="boxed"
        type="secondary"
        onClick={() => setTemplatesModal(true)}
      >
        Templates
      </Button>
    </div>
  );
}

export default Playground;

import {
  saveApplication,
  exchangeToken as exchangePlaidToken,
} from "_graphql/mutations/application";
import { saveTradelines as mutateTradelines } from "_graphql/mutations/tradeline";
import {
  sendEmail,
  sendText,
  toggleManual,
} from "App/Admin/_graphql/mutations/application";
import { getWizardApplication } from "_graphql/queries/application";
import { saveBudget } from "App/Admin/_graphql/mutations/budget";

export const getForm = async (apolloClient, applicationId) => {
  let currentForm = null;

  // Attempt to grab form data or use blank form
  try {
    const result = await apolloClient.query({
      query: getWizardApplication,
      variables: {
        id: applicationId,
      },
    });
    if (result?.data?.getApplication) {
      currentForm = result?.data?.getApplication;
    }
  } catch (err) {
    currentForm = null;
  }

  const status = currentForm?.status;
  const shortId = currentForm?.short_id;
  const manual = currentForm?.allow_manual;
  const bank = currentForm?.bank ? { ...currentForm.bank } : null;
  const wasCancelled = currentForm?.was_cancelled || false;
  const creditSkipped = currentForm?.skip_credit_report || false;
  if (currentForm) {
    delete currentForm.status;
    delete currentForm.short_id;
    delete currentForm.allow_manual;
    delete currentForm.bank;
    delete currentForm.was_cancelled;
    delete currentForm.skip_credit_report;
  }

  // Return entire form or just a part depending on what is requested
  return {
    short_id: shortId,
    manual,
    bank,
    status,
    currentForm,
    wasCancelled,
    creditSkipped,
  };
};

export const saveForm = async (client, form) => {
  let formResult = null;
  try {
    const formData = { ...form };
    formResult = await client.mutate({
      variables: {
        application: formData,
      },
      mutation: saveApplication,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult?.data?.saveApplication || false;
};

export const exchangeToken = async (client, form, plaidData) => {
  let exchangeResult = null;
  try {
    exchangeResult = await client.mutate({
      variables: {
        application_id: form.id,
        public_token: plaidData?.public_token,
        account_id: plaidData?.metadata?.account_id,
      },
      mutation: exchangePlaidToken,
    });
  } catch (err) {
    exchangeResult = null;
    console.log(err);
  }
  return exchangeResult?.data?.exchangeToken;
};

export const saveTradelines = async (client, tradelines, application_id) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: { tradelines, application_id },
      mutation: mutateTradelines,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult ? true : false;
};

export const sendCustomerEmail = async (client, form, email, newBank) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: {
        application_id: form?.id,
        email: email,
        new_bank: newBank || false,
      },
      mutation: sendEmail,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult?.data?.sendEmail || false;
};

export const sendCustomerText = async (client, form, phone, newBank) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: {
        application_id: form?.id,
        phone: phone,
        new_bank: newBank || false,
      },
      mutation: sendText,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult?.data?.sendText || false;
};

export const updateManual = async (client, form, allow_manual) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: {
        application_id: form?.id,
        allow_manual: allow_manual,
      },
      mutation: toggleManual,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult?.data?.toggleManual || false;
};

export const updateBudget = async (client, budget, application_id) => {
  let formResult = null;
  try {
    formResult = await client.mutate({
      variables: {
        application_id: application_id,
        items: budget,
      },
      mutation: saveBudget,
    });
  } catch (err) {
    formResult = null;
    console.log(err);
  }
  return formResult?.data?.saveBudget || null;
};

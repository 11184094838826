import { gql } from "@apollo/client";

export const adminCompanies = gql`
  query {
    adminCompanies {
      id
      short_id
      name
      user_id
      companies
      user_count
      application_count
      subscription_count
      role

      user {
        id
        short_id
        name
        email
      }

      invited_date
      joined_date
      createdAt
    }
  }
`;

export const adminPartners = gql`
  query {
    adminPartners {
      id
      short_id
      name
      user_id
      companies
      user_count
      application_count
      subscription_count
      role

      user {
        id
        short_id
        name
        email
      }

      invited_date
      joined_date
      createdAt
    }
  }
`;

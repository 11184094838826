import { useState, useEffect } from "react";
import { CallsClient } from "_graphql/clients/CallsClient";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconMessage } from "@tabler/icons-react";
import { TextArea } from "_styleguide";
import { getTextMessages } from "_graphql/queries/textmessage";
import { createTextMessage } from "_graphql/mutations/textmessage";
import "./Texting.scss";

const APPLICATION_ID = "665dfe41354ab18e895c3da0";

function Texting(props) {
  const client = CallsClient;
  const [visible, setVisible] = useState(true);
  const [sending, setSending] = useState(false);
  const [texts, setTexts] = useState([]);
  const [text, setText] = useState(null);

  // Load Text Messages
  const fetchMessages = async () => {
    const queryData = await client.query({
      variables: { application_id: APPLICATION_ID },
      query: getTextMessages,
    });
    setTexts(queryData?.data?.getTextMessages || []);
  };

  const sendText = async () => {
    setSending(true);
    try {
      await client.mutate({
        variables: {
          application_id: APPLICATION_ID,
          task_id: null,
          content: text,
        },
        mutation: createTextMessage,
      });
      message.success("Message sent");
      fetchMessages();
      setText(null);
      setSending(false);
    } catch (err) {
      message.error(getErrors(err) || "Error sending message, try again");
      setSending(false);
    }
  };

  useEffect(() => {
    fetchMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      wrapClassName="texting-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={true}
      width={640}
    >
      <div className="pp-modal-header">
        <div>
          <IconMessage />
        </div>
        <h3>Text Messages</h3>
      </div>

      <Modal.Body>
        <div className="chat-window">
          {texts?.map((text, i) => {
            let className = "message";
            let from = text.user?.name;
            if (text.outgoing) {
              className += " right";
            } else {
              from =
                text.application?.first_name +
                " " +
                text.application?.last_name;
              className += " left";
            }
            return (
              <div key={text.id} className={className}>
                <div>{from}</div>
                {text.content || " "}
              </div>
            );
          })}
        </div>
      </Modal.Body>

      <Modal.Actions>
        <div className="text-compose">
          <TextArea
            disabled={sending}
            value={text}
            onChange={(e) => setText(e.target.value)}
            size="large"
            autoSize={true}
            placeholder="Message..."
          />
          <Button
            disabled={!text || text?.length < 1}
            loading={sending}
            type="primary"
            size="small"
            onClick={() => sendText()}
          >
            Send Message
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
}

export default Texting;

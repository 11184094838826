import React, { Component } from "react";
import { IconX } from "@tabler/icons-react";
import PayPathLogo from "_assets/logos/icon.svg";
import "./InfoWindow.scss";

class InfoWindow extends Component {
  render() {
    const props = { ...this.props };
    props.className = "pp-info-window " + (props.className || "");

    return (
      <div className={props.className}>
        <div className="pp-window-bg" />
        <div className="pp-window-box">
          <div className="pp-window-close" onClick={this.props.onClose}>
            <IconX />
          </div>
          <div className="pp-window-logo">
            <img src={PayPathLogo} alt="PayPath" />
          </div>
          {props.children}
        </div>
      </div>
    );
  }
}

export default InfoWindow;

import { gql } from "@apollo/client";

export const createTextMessage = gql`
  mutation createTextMessage(
    $application_id: String!
    $task_id: String
    $content: String!
  ) {
    createTextMessage(
      application_id: $application_id
      task_id: $task_id
      content: $content
    )
  }
`;

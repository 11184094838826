import { gql } from "@apollo/client";

export const getIntegrations = gql`
  query {
    getIntegrations {
      id
      type
      category
      settings
      active
      connected
      user_id
      company_id
      createdAt
      updatedAt
    }
  }
`;

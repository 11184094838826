import { useState, useEffect } from "react";
import { Search, Empty } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import { useNavigate } from "react-router-dom";
import { getThreads } from "_graphql/queries/email";
import { IconPuzzle, IconPlus } from "@tabler/icons-react";
import { useIntegrations } from "_contexts/useIntegrations";
import { stringifyValues } from "_assets/js/helpers";
import Thread from "./_components/Thread/Thread";
import Conversation from "./_components/Conversation/Conversation";
import { Button } from "@centrate-io/barn";
import { useBar } from "_contexts/useBar";
import "./Email.scss";

function Email(props) {
  const callsClient = CallsClient;
  const navigate = useNavigate();
  const { setupEmail, customer } = useBar();
  const { integrations } = useIntegrations();
  const [threads, setThreads] = useState([]);
  const [selected, setSelected] = useState();
  const [search, setSearch] = useState();
  const [loading, setLoading] = useState(true);

  // Load Text Messages
  const fetchThreads = async () => {
    const queryData = await callsClient.query({
      variables: {
        application_id: props.applicationId,
      },
      query: getThreads,
    });
    setThreads(queryData?.data?.getThreads || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchThreads();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (threads?.length <= 0 && !integrations.email) {
    return (
      <Empty
        title="No Email Integration"
        description="Setup an Email integration to send and receive emails."
        action="Setup Integration"
        actionIcon={<IconPuzzle />}
        onClick={() => navigate("/settings/integrations")}
      />
    );
  }

  const compose = () => {
    setupEmail(customer, null, true, {
      reloadThreads: fetchThreads,
    });
  };

  const filteredThreads = threads.filter((t) => {
    if (!search || search?.length < 3) return true;
    const tData = stringifyValues(t);
    return tData?.toLowerCase()?.includes(search?.toLowerCase());
  });

  const currentThread = threads?.find((t) => t.id === selected);

  return (
    <div className="admin-application-email">
      <div className="threads">
        <div className="threads-search">
          <Search
            disabled={loading}
            placeholder="Search..."
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <Button
            type="secondary"
            className="boxed"
            size="small"
            onClick={compose}
          >
            <IconPlus /> New
          </Button>
        </div>
        <div className="threads-list">
          {!loading &&
            filteredThreads?.map((t) => (
              <Thread
                key={t.id}
                thread={t}
                userData={props.user}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
          {loading ? <p className="thread-loading">Loading emails...</p> : null}
        </div>
      </div>
      <div className="email-info">
        {!selected ? (
          <Empty
            title="Select an email thread"
            description="Nothing is selected"
          />
        ) : (
          <div className="convo-full">
            <div className="convo-subject">
              <div>{currentThread?.subject}</div>
            </div>
            <Conversation
              applicationId={props.applicationId}
              threadId={selected}
              thread={currentThread}
              lastEmail={currentThread?.latest_email?.id}
              userData={props.user}
              reloadThreads={fetchThreads}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Email;

import { gql } from "@apollo/client";

export const getTextMessages = gql`
  query getTextMessages($application_id: ID!) {
    getTextMessages(application_id: $application_id) {
      id
      twilio_id
      status
      from_number
      to_number
      user {
        id
        name
        email
      }
      application {
        id
        first_name
        last_name
      }
      opt_out
      outgoing
      incoming
      content
      createdAt
    }
  }
`;

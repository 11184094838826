import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { IconPencil } from "@tabler/icons-react";
import {
  Loading,
  FormInput,
  InputLabel,
  Select,
  Autocomplete,
} from "_styleguide";
import { Button, message } from "@centrate-io/barn";
import { validateAll } from "_helpers/validateForm";
import { STATES } from "_helpers/location";
import { getCompany } from "_graphql/queries/company";
import { saveCompany } from "_graphql/mutations/company";
import { getErrors } from "_helpers/api";
import CompanyLogo from "./_modals/CompanyLogo/CompanyLogo";
import "./Manage.scss";

function Manage(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState();
  const [savedForm, setSavedForm] = useState();
  const [logoModal, setLogoModal] = useState(false);

  // Load Settings
  const fetchSettings = async () => {
    const companyData = await client.query({
      query: getCompany,
    });
    const formData = companyData?.data?.getCompany;
    setForm(formData);
    setSavedForm(JSON.stringify(formData));
    setLoading(false);
  };

  const saveForm = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: {
          company: { ...form },
        },
        mutation: saveCompany,
      });
      fetchSettings();
      if (props.fetchLogin) props.fetchLogin();
      setSaving(false);
      message.success("Company successfully updated");
    } catch (err) {
      message.error(getErrors(err) || "Error updating company, try again");
      setSaving(false);
    }
  };

  const u = (f, v) => {
    const n = { ...form };
    n[f] = v;
    setForm(n);
  };

  const ua = (oldForm, values) => {
    setForm({ ...oldForm, ...values });
  };

  useEffect(() => {
    props?.setCurrentCount(0);
    props.setCrumbs({
      title: "Manage",
      icon: <IconPencil />,
      description: "Manage your companies information",
    });
    fetchSettings();
  }, []);

  const validation = validateAll(form);
  const isEdited = JSON.stringify(form) !== savedForm;

  return loading ? (
    <div className="settings-manage">
      <Loading />
    </div>
  ) : (
    <div className="settings-manage">
      {logoModal ? (
        <CompanyLogo
          reload={fetchSettings}
          removeModal={() => setLogoModal(false)}
          fetchLogin={props.fetchLogin}
        />
      ) : null}
      {!props.readOnly ? (
        <div className="settings-search">
          <div className="ms-actions">
            <Button
              disabled={!isEdited}
              type="primary"
              size="small"
              loading={saving}
              onClick={saveForm}
            >
              Save
            </Button>
          </div>
        </div>
      ) : null}
      <div className="manage-box">
        <div className="manage-header">
          <h3>General Information</h3>
        </div>
        <div className="manage-body">
          <div className="form-item">
            <InputLabel htmlFor="logo">Company Logo</InputLabel>
            <div className="logo-box">
              {form.logo ? (
                <img className="company-logo" src={form.logo} alt="Logo" />
              ) : null}
              {!props.readOnly ? (
                <Button
                  size="small"
                  className="boxed"
                  type="secondary"
                  onClick={() => setLogoModal(true)}
                >
                  Change Logo
                </Button>
              ) : null}
            </div>
          </div>
          <div className="form-item">
            <InputLabel htmlFor="name" {...validation.name}>
              Company Name *
            </InputLabel>
            <FormInput
              id="name"
              value={form.name}
              onChange={(e) => u("name", e.target.value)}
              size="medium"
              placeholder="e.g. My Company"
              {...validation.name}
            />
          </div>
        </div>
      </div>
      <div className="manage-box">
        <div className="manage-header">
          <h3>Company Address</h3>
        </div>
        <div className="manage-body">
          <div className="form-item">
            <InputLabel {...validation.address}>Address *</InputLabel>
            <Autocomplete
              value={form.address}
              onChange={(v) => u("address", v)}
              form={form}
              updateAddress={ua}
              size="medium"
              {...validation.address}
            />
            <FormInput
              id="address_line_two"
              value={form.address_line_two}
              onChange={(e) => u("address_line_two", e.target.value)}
              placeholder="Apartment, suite, unit (optional)"
              size="medium"
              style={{ marginTop: "16px" }}
            />
          </div>
          <div className="form-item">
            <InputLabel htmlFor="city" {...validation.city}>
              City *
            </InputLabel>
            <FormInput
              id="city"
              value={form.city}
              onChange={(e) => u("city", e.target.value)}
              size="medium"
              {...validation.city}
            />
          </div>
          <div className="form-row">
            <div>
              <InputLabel htmlFor="state" {...validation.state}>
                State *
              </InputLabel>
              <Select
                value={form.state}
                onChange={(val) => u("city", val)}
                size="extra-medium"
                popupClassName="pp-select-dropdown"
                {...validation.state}
              >
                {STATES.map((s) => (
                  <Select.Option key={s.value} value={s.value}>
                    {s.label}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div style={{ maxWidth: "160px" }}>
              <InputLabel htmlFor="zip" {...validation.zip}>
                Zip *
              </InputLabel>
              <FormInput
                id="zip"
                value={form.zip}
                onChange={(e) => u("city", e.target.value)}
                size="medium"
                maxLength="5"
                {...validation.zip}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manage;

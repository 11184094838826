export const AREA_CODES = [
  {
    code: "201",
    state: "New Jersey",
  },
  {
    code: "202",
    state: "Washington, DC",
  },
  {
    code: "203",
    state: "Connecticut",
  },
  {
    code: "205",
    state: "Alabama",
  },
  {
    code: "206",
    state: "Washington",
  },
  {
    code: "207",
    state: "Maine",
  },
  {
    code: "208",
    state: "Idaho",
  },
  {
    code: "209",
    state: "California",
  },
  {
    code: "210",
    state: "Texas",
  },
  {
    code: "212",
    state: "New York",
  },
  {
    code: "213",
    state: "California",
  },
  {
    code: "214",
    state: "Texas",
  },
  {
    code: "215",
    state: "Pennsylvania",
  },
  {
    code: "216",
    state: "Ohio",
  },
  {
    code: "217",
    state: "Illinois",
  },
  {
    code: "218",
    state: "Minnesota",
  },
  {
    code: "219",
    state: "Indiana",
  },
  {
    code: "224",
    state: "Illinois",
  },
  {
    code: "225",
    state: "Louisiana",
  },
  {
    code: "228",
    state: "Mississippi",
  },
  {
    code: "229",
    state: "Georgia",
  },
  {
    code: "231",
    state: "Michigan",
  },
  {
    code: "234",
    state: "Ohio",
  },
  {
    code: "239",
    state: "Florida",
  },
  {
    code: "240",
    state: "Maryland",
  },
  {
    code: "248",
    state: "Michigan",
  },
  {
    code: "251",
    state: "Alabama",
  },
  {
    code: "252",
    state: "North Carolina",
  },
  {
    code: "253",
    state: "Washington",
  },
  {
    code: "254",
    state: "Texas",
  },
  {
    code: "256",
    state: "Alabama",
  },
  {
    code: "260",
    state: "Indiana",
  },
  {
    code: "262",
    state: "Wisconsin",
  },
  {
    code: "267",
    state: "Pennsylvania",
  },
  {
    code: "269",
    state: "Michigan",
  },
  {
    code: "270",
    state: "Kentucky",
  },
  {
    code: "272",
    state: "Pennsylvania",
  },
  {
    code: "276",
    state: "Virginia",
  },
  {
    code: "281",
    state: "Texas",
  },
  {
    code: "301",
    state: "Maryland",
  },
  {
    code: "302",
    state: "Delaware",
  },
  {
    code: "303",
    state: "Colorado",
  },
  {
    code: "304",
    state: "West Virginia",
  },
  {
    code: "305",
    state: "Florida",
  },
  {
    code: "307",
    state: "Wyoming",
  },
  {
    code: "308",
    state: "Nebraska",
  },
  {
    code: "309",
    state: "Illinois",
  },
  {
    code: "310",
    state: "California",
  },
  {
    code: "312",
    state: "Illinois",
  },
  {
    code: "313",
    state: "Michigan",
  },
  {
    code: "314",
    state: "Missouri",
  },
  {
    code: "315",
    state: "New York",
  },
  {
    code: "316",
    state: "Kansas",
  },
  {
    code: "317",
    state: "Indiana",
  },
  {
    code: "318",
    state: "Louisiana",
  },
  {
    code: "319",
    state: "Iowa",
  },
  {
    code: "320",
    state: "Minnesota",
  },
  {
    code: "321",
    state: "Florida",
  },
  {
    code: "323",
    state: "California",
  },
  {
    code: "325",
    state: "Texas",
  },
  {
    code: "330",
    state: "Ohio",
  },
  {
    code: "331",
    state: "Illinois",
  },
  {
    code: "334",
    state: "Alabama",
  },
  {
    code: "336",
    state: "North Carolina",
  },
  {
    code: "337",
    state: "Louisiana",
  },
  {
    code: "339",
    state: "Massachusetts",
  },
  {
    code: "340",
    state: "Virgin Islands",
  },
  {
    code: "346",
    state: "Texas",
  },
  {
    code: "347",
    state: "New York",
  },
  {
    code: "351",
    state: "Massachusetts",
  },
  {
    code: "352",
    state: "Florida",
  },
  {
    code: "360",
    state: "Washington",
  },
  {
    code: "361",
    state: "Texas",
  },
  {
    code: "385",
    state: "Utah",
  },
  {
    code: "386",
    state: "Florida",
  },
  {
    code: "401",
    state: "Rhode Island",
  },
  {
    code: "402",
    state: "Nebraska",
  },
  {
    code: "404",
    state: "Georgia",
  },
  {
    code: "405",
    state: "Oklahoma",
  },
  {
    code: "406",
    state: "Montana",
  },
  {
    code: "407",
    state: "Florida",
  },
  {
    code: "408",
    state: "California",
  },
  {
    code: "409",
    state: "Texas",
  },
  {
    code: "410",
    state: "Maryland",
  },
  {
    code: "412",
    state: "Pennsylvania",
  },
  {
    code: "413",
    state: "Massachusetts",
  },
  {
    code: "414",
    state: "Wisconsin",
  },
  {
    code: "415",
    state: "California",
  },
  {
    code: "417",
    state: "Missouri",
  },
  {
    code: "419",
    state: "Ohio",
  },
  {
    code: "423",
    state: "Tennessee",
  },
  {
    code: "424",
    state: "California",
  },
  {
    code: "425",
    state: "Washington",
  },
  {
    code: "430",
    state: "Texas",
  },
  {
    code: "432",
    state: "Texas",
  },
  {
    code: "434",
    state: "Virginia",
  },
  {
    code: "435",
    state: "Utah",
  },
  {
    code: "440",
    state: "Ohio",
  },
  {
    code: "442",
    state: "California",
  },
  {
    code: "443",
    state: "Maryland",
  },
  {
    code: "458",
    state: "Oregon",
  },
  {
    code: "469",
    state: "Texas",
  },
  {
    code: "470",
    state: "Georgia",
  },
  {
    code: "475",
    state: "Connecticut",
  },
  {
    code: "478",
    state: "Georgia",
  },
  {
    code: "479",
    state: "Arkansas",
  },
  {
    code: "480",
    state: "Arizona",
  },
  {
    code: "484",
    state: "Pennsylvania",
  },
  {
    code: "501",
    state: "Arkansas",
  },
  {
    code: "502",
    state: "Kentucky",
  },
  {
    code: "503",
    state: "Oregon",
  },
  {
    code: "504",
    state: "Louisiana",
  },
  {
    code: "505",
    state: "New Mexico",
  },
  {
    code: "507",
    state: "Minnesota",
  },
  {
    code: "508",
    state: "Massachusetts",
  },
  {
    code: "509",
    state: "Washington",
  },
  {
    code: "510",
    state: "California",
  },
  {
    code: "512",
    state: "Texas",
  },
  {
    code: "513",
    state: "Ohio",
  },
  {
    code: "515",
    state: "Iowa",
  },
  {
    code: "516",
    state: "New York",
  },
  {
    code: "517",
    state: "Michigan",
  },
  {
    code: "518",
    state: "New York",
  },
  {
    code: "520",
    state: "Arizona",
  },
  {
    code: "530",
    state: "California",
  },
  {
    code: "531",
    state: "Nebraska",
  },
  {
    code: "534",
    state: "Wisconsin",
  },
  {
    code: "539",
    state: "Oklahoma",
  },
  {
    code: "540",
    state: "Virginia",
  },
  {
    code: "541",
    state: "Oregon",
  },
  {
    code: "551",
    state: "New Jersey",
  },
  {
    code: "559",
    state: "California",
  },
  {
    code: "561",
    state: "Florida",
  },
  {
    code: "562",
    state: "California",
  },
  {
    code: "563",
    state: "Iowa",
  },
  {
    code: "567",
    state: "Ohio",
  },
  {
    code: "570",
    state: "Pennsylvania",
  },
  {
    code: "571",
    state: "Virginia",
  },
  {
    code: "573",
    state: "Missouri",
  },
  {
    code: "574",
    state: "Indiana",
  },
  {
    code: "575",
    state: "New Mexico",
  },
  {
    code: "580",
    state: "Oklahoma",
  },
  {
    code: "585",
    state: "New York",
  },
  {
    code: "586",
    state: "Michigan",
  },
  {
    code: "601",
    state: "Mississippi",
  },
  {
    code: "602",
    state: "Arizona",
  },
  {
    code: "603",
    state: "New Hampshire",
  },
  {
    code: "605",
    state: "South Dakota",
  },
  {
    code: "606",
    state: "Kentucky",
  },
  {
    code: "607",
    state: "New York",
  },
  {
    code: "608",
    state: "Wisconsin",
  },
  {
    code: "609",
    state: "New Jersey",
  },
  {
    code: "610",
    state: "Pennsylvania",
  },
  {
    code: "612",
    state: "Minnesota",
  },
  {
    code: "614",
    state: "Ohio",
  },
  {
    code: "615",
    state: "Tennessee",
  },
  {
    code: "616",
    state: "Michigan",
  },
  {
    code: "617",
    state: "Massachusetts",
  },
  {
    code: "618",
    state: "Illinois",
  },
  {
    code: "619",
    state: "California",
  },
  {
    code: "620",
    state: "Kansas",
  },
  {
    code: "623",
    state: "Arizona",
  },
  {
    code: "626",
    state: "California",
  },
  {
    code: "630",
    state: "Illinois",
  },
  {
    code: "631",
    state: "New York",
  },
  {
    code: "636",
    state: "Missouri",
  },
  {
    code: "641",
    state: "Iowa",
  },
  {
    code: "646",
    state: "New York",
  },
  {
    code: "650",
    state: "California",
  },
  {
    code: "651",
    state: "Minnesota",
  },
  {
    code: "657",
    state: "California",
  },
  {
    code: "660",
    state: "Missouri",
  },
  {
    code: "661",
    state: "California",
  },
  {
    code: "662",
    state: "Mississippi",
  },
  {
    code: "667",
    state: "Maryland",
  },
  {
    code: "669",
    state: "California",
  },
  {
    code: "670",
    state: "Northern Mariana Islands",
  },
  {
    code: "671",
    state: "Guam",
  },
  {
    code: "678",
    state: "Georgia",
  },
  {
    code: "681",
    state: "West Virginia",
  },
  {
    code: "682",
    state: "Texas",
  },
  {
    code: "684",
    state: "American Samoa",
  },
  {
    code: "701",
    state: "North Dakota",
  },
  {
    code: "702",
    state: "Nevada",
  },
  {
    code: "703",
    state: "Virginia",
  },
  {
    code: "704",
    state: "North Carolina",
  },
  {
    code: "706",
    state: "Georgia",
  },
  {
    code: "707",
    state: "California",
  },
  {
    code: "708",
    state: "Illinois",
  },
  {
    code: "712",
    state: "Iowa",
  },
  {
    code: "713",
    state: "Texas",
  },
  {
    code: "714",
    state: "California",
  },
  {
    code: "715",
    state: "Wisconsin",
  },
  {
    code: "716",
    state: "New York",
  },
  {
    code: "717",
    state: "Pennsylvania",
  },
  {
    code: "718",
    state: "New York",
  },
  {
    code: "719",
    state: "Colorado",
  },
  {
    code: "720",
    state: "Colorado",
  },
  {
    code: "724",
    state: "Pennsylvania",
  },
  {
    code: "725",
    state: "Nevada",
  },
  {
    code: "727",
    state: "Florida",
  },
  {
    code: "731",
    state: "Tennessee",
  },
  {
    code: "732",
    state: "New Jersey",
  },
  {
    code: "734",
    state: "Michigan",
  },
  {
    code: "737",
    state: "Texas",
  },
  {
    code: "740",
    state: "Ohio",
  },
  {
    code: "747",
    state: "California",
  },
  {
    code: "754",
    state: "Florida",
  },
  {
    code: "757",
    state: "Virginia",
  },
  {
    code: "760",
    state: "California",
  },
  {
    code: "762",
    state: "Georgia",
  },
  {
    code: "763",
    state: "Minnesota",
  },
  {
    code: "765",
    state: "Indiana",
  },
  {
    code: "769",
    state: "Mississippi",
  },
  {
    code: "770",
    state: "Georgia",
  },
  {
    code: "772",
    state: "Florida",
  },
  {
    code: "773",
    state: "Illinois",
  },
  {
    code: "774",
    state: "Massachusetts",
  },
  {
    code: "775",
    state: "Nevada",
  },
  {
    code: "779",
    state: "Illinois",
  },
  {
    code: "781",
    state: "Massachusetts",
  },
  {
    code: "785",
    state: "Kansas",
  },
  {
    code: "786",
    state: "Florida",
  },
  {
    code: "787",
    state: "Puerto Rico",
  },
  {
    code: "801",
    state: "Utah",
  },
  {
    code: "802",
    state: "Vermont",
  },
  {
    code: "803",
    state: "South Carolina",
  },
  {
    code: "804",
    state: "Virginia",
  },
  {
    code: "805",
    state: "California",
  },
  {
    code: "806",
    state: "Texas",
  },
  {
    code: "808",
    state: "Hawaii",
  },
  {
    code: "810",
    state: "Michigan",
  },
  {
    code: "812",
    state: "Indiana",
  },
  {
    code: "813",
    state: "Florida",
  },
  {
    code: "814",
    state: "Pennsylvania",
  },
  {
    code: "815",
    state: "Illinois",
  },
  {
    code: "816",
    state: "Missouri",
  },
  {
    code: "817",
    state: "Texas",
  },
  {
    code: "818",
    state: "California",
  },
  {
    code: "828",
    state: "North Carolina",
  },
  {
    code: "830",
    state: "Texas",
  },
  {
    code: "831",
    state: "California",
  },
  {
    code: "832",
    state: "Texas",
  },
  {
    code: "843",
    state: "South Carolina",
  },
  {
    code: "845",
    state: "New York",
  },
  {
    code: "847",
    state: "Illinois",
  },
  {
    code: "848",
    state: "New Jersey",
  },
  {
    code: "850",
    state: "Florida",
  },
  {
    code: "856",
    state: "New Jersey",
  },
  {
    code: "857",
    state: "Massachusetts",
  },
  {
    code: "858",
    state: "California",
  },
  {
    code: "859",
    state: "Kentucky",
  },
  {
    code: "860",
    state: "Connecticut",
  },
  {
    code: "862",
    state: "New Jersey",
  },
  {
    code: "863",
    state: "Florida",
  },
  {
    code: "864",
    state: "South Carolina",
  },
  {
    code: "865",
    state: "Tennessee",
  },
  {
    code: "870",
    state: "Arkansas",
  },
  {
    code: "872",
    state: "Illinois",
  },
  {
    code: "878",
    state: "Pennsylvania",
  },
  {
    code: "901",
    state: "Tennessee",
  },
  {
    code: "903",
    state: "Texas",
  },
  {
    code: "904",
    state: "Florida",
  },
  {
    code: "906",
    state: "Michigan",
  },
  {
    code: "907",
    state: "Alaska",
  },
  {
    code: "908",
    state: "New Jersey",
  },
  {
    code: "909",
    state: "California",
  },
  {
    code: "910",
    state: "North Carolina",
  },
  {
    code: "912",
    state: "Georgia",
  },
  {
    code: "913",
    state: "Kansas",
  },
  {
    code: "914",
    state: "New York",
  },
  {
    code: "915",
    state: "Texas",
  },
  {
    code: "916",
    state: "California",
  },
  {
    code: "917",
    state: "New York",
  },
  {
    code: "918",
    state: "Oklahoma",
  },
  {
    code: "919",
    state: "North Carolina",
  },
  {
    code: "920",
    state: "Wisconsin",
  },
  {
    code: "925",
    state: "California",
  },
  {
    code: "928",
    state: "Arizona",
  },
  {
    code: "929",
    state: "New York",
  },
  {
    code: "931",
    state: "Tennessee",
  },
  {
    code: "936",
    state: "Texas",
  },
  {
    code: "937",
    state: "Ohio",
  },
  {
    code: "938",
    state: "Alabama",
  },
  {
    code: "939",
    state: "Puerto Rico",
  },
  {
    code: "940",
    state: "Texas",
  },
  {
    code: "941",
    state: "Florida",
  },
  {
    code: "947",
    state: "Michigan",
  },
  {
    code: "949",
    state: "California",
  },
  {
    code: "951",
    state: "California",
  },
  {
    code: "952",
    state: "Minnesota",
  },
  {
    code: "954",
    state: "Florida",
  },
  {
    code: "956",
    state: "Texas",
  },
  {
    code: "970",
    state: "Colorado",
  },
  {
    code: "971",
    state: "Oregon",
  },
  {
    code: "972",
    state: "Texas",
  },
  {
    code: "973",
    state: "New Jersey",
  },
  {
    code: "978",
    state: "Massachusetts",
  },
  {
    code: "979",
    state: "Texas",
  },
  {
    code: "980",
    state: "North Carolina",
  },
  {
    code: "984",
    state: "North Carolina",
  },
  {
    code: "985",
    state: "Louisiana",
  },
  {
    code: "989",
    state: "Michigan",
  },
];

import React, { useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import { adminForm } from "App/Admin/_graphql/queries/application";
import {
  Loading,
  FormInput,
  InputLabel,
  Select,
  Autocomplete,
} from "_styleguide";
import { FormatPhone, FormatSSN } from "_assets/js/helpers";
import { validateAll } from "_helpers/validateForm";
import { STATES } from "_helpers/location";
import { Button, message } from "@centrate-io/barn";
import { saveForm } from "_styleguide/Actions/saveForm";
import { getErrors } from "_helpers/api";
import "./Information.scss";

function Information(props) {
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState();
  const [savedForm, setSavedForm] = useState();

  const u = (f, v) => {
    const n = { ...form };
    if (f === "phone") {
      n[f] = FormatPhone(v);
    } else if (f === "ssn") {
      n[f] = FormatSSN(v);
    } else {
      n[f] = v;
    }
    setForm(n);
  };

  const ua = (oldForm, values) => {
    setForm({ ...oldForm, ...values });
  };

  // Load Entries
  const fetchApplication = async () => {
    const applicationData = await client.query({
      variables: { id: props?.applicationId },
      query: adminForm,
    });
    const app = applicationData?.data?.adminApplication;
    const appForm = {
      ...app,
    };
    setForm(appForm);
    setSavedForm(JSON.stringify(appForm));
    setLoading(false);
  };

  const saveApplication = async (form) => {
    setSaving(true);
    try {
      const savedForm = {
        ...form,
      };
      await saveForm(client, { ...savedForm }, "admin");
      await fetchApplication();
      setSaving(false);
      message.success("Application saved");
    } catch (err) {
      message.error(getErrors(err) || "Error saving application, try again");
      setSaving(false);
    }
  };

  useEffect(() => {
    fetchApplication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validation = validateAll(form);
  const isEdited = JSON.stringify(form) !== savedForm;
  const isValid = validation?.allValid ? true : false;

  return loading || !form ? (
    <Loading />
  ) : (
    <div className="admin-application-information">
      <div className="admin-information-boxes">
        {!props.readOnly ? (
          <div className="admin-bar admin-button">
            {isEdited && <p className="is-edited">Edited</p>}

            <Button
              disabled={!isEdited || !isValid}
              loading={saving}
              type="primary"
              onClick={() => saveApplication(form)}
            >
              Save Application
            </Button>
          </div>
        ) : null}
        <div className="wzrd-box">
          <div className="wzrd-header">
            <h3>User Information</h3>
          </div>
          <div className="wzrd-body">
            <div className="form-row">
              <div>
                <InputLabel htmlFor="first_name" {...validation.first_name}>
                  First name *
                </InputLabel>
                <FormInput
                  id="first_name"
                  value={form.first_name}
                  size="medium"
                  onChange={(e) => u("first_name", e.target.value)}
                  placeholder="e.g. James..."
                  {...validation.first_name}
                />
              </div>
              <div>
                <InputLabel htmlFor="last_name" {...validation.last_name}>
                  Last name *
                </InputLabel>
                <FormInput
                  id="last_name"
                  value={form.last_name}
                  size="medium"
                  onChange={(e) => u("last_name", e.target.value)}
                  placeholder="e.g. Ford..."
                  {...validation.last_name}
                />
              </div>
            </div>
            <div className="form-item">
              <InputLabel htmlFor="email" {...validation.email}>
                Email address *
              </InputLabel>
              <FormInput
                id="email"
                value={form.email}
                onChange={(e) => u("email", e.target.value?.toLowerCase())}
                size="medium"
                placeholder="e.g. jford@gmail.com..."
                {...validation.email}
              />
            </div>
            <div className="form-item">
              <InputLabel htmlFor="phone" {...validation.phone}>
                Primary phone number *
              </InputLabel>
              <FormInput
                id="phone"
                value={form.phone}
                onChange={(e) => u("phone", e.target.value)}
                size="medium"
                placeholder="e.g. 631-294-2293..."
                {...validation.phone}
              />
            </div>
          </div>
        </div>
        <div className="wzrd-box">
          <div className="wzrd-header">
            <h3>Home Address</h3>
          </div>
          <div className="wzrd-body">
            <div className="form-item">
              <InputLabel {...validation.address}>Address *</InputLabel>
              <Autocomplete
                value={form.address}
                onChange={(v) => u("address", v)}
                form={form}
                updateAddress={ua}
                size="medium"
                {...validation.address}
              />
              <FormInput
                id="address_line_two"
                value={form.address_line_two}
                onChange={(e) => u("address_line_two", e.target.value)}
                placeholder="Apartment, suite, unit (optional)"
                size="medium"
                style={{ marginTop: "16px" }}
              />
            </div>
            <div className="form-item">
              <InputLabel htmlFor="city" {...validation.city}>
                City *
              </InputLabel>
              <FormInput
                id="city"
                value={form.city}
                onChange={(e) => u("city", e.target.value)}
                size="medium"
                {...validation.city}
              />
            </div>
            <div className="form-row">
              <div>
                <InputLabel htmlFor="state" {...validation.state}>
                  State *
                </InputLabel>
                <Select
                  value={form.state}
                  onChange={(val) => u("city", val)}
                  size="extra-medium"
                  popupClassName="pp-select-dropdown"
                  {...validation.state}
                >
                  {STATES.map((s) => (
                    <Select.Option key={s.value} value={s.value}>
                      {s.label}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div style={{ maxWidth: "160px" }}>
                <InputLabel htmlFor="zip" {...validation.zip}>
                  Zip *
                </InputLabel>
                <FormInput
                  id="zip"
                  value={form.zip}
                  onChange={(e) => u("city", e.target.value)}
                  size="medium"
                  maxLength="5"
                  {...validation.zip}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Information;

import React, { useRef, useEffect } from "react";

const ResizableIframe = ({ c }) => {
  const iframeRef = useRef(null);

  const adjustIframeHeight = () => {
    const iframe = iframeRef.current;
    if (iframe) {
      try {
        const contentHeight = iframe.contentWindow.document.body.scrollHeight;
        iframe.style.minHeight = `60px`;
        iframe.style.height = `${Math.min(contentHeight, 600)}px`;
      } catch (error) {
        console.error("Failed to adjust iframe height:", error);
      }
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.addEventListener("load", adjustIframeHeight);
      return () => iframe.removeEventListener("load", adjustIframeHeight);
    }
  }, []);

  return (
    <iframe
      ref={iframeRef}
      srcDoc={c?.body || ""}
      title={c?.subject}
      style={{ width: "100%", border: 0 }}
      sandbox="allow-same-origin"
    />
  );
};

export default ResizableIframe;

import React from "react";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs-plugin-utc";
import {
  IconInfoOctagon,
  IconOctagon,
  IconCalendarMonth,
  IconCoin,
  IconRepeat,
  IconClock,
} from "@tabler/icons-react";
import { flatMoney, formatMoney } from "_assets/js/helpers";
import "./Review.scss";

dayjs.extend(dayjsPluginUTC);

function Review(props) {
  const { form } = props;

  let dateValue = undefined;
  const plan = form?.plan;
  if (plan?.start_date) {
    if (plan?.start_date?.length <= 13) {
      dateValue = dayjs(parseInt(plan?.start_date));
    } else {
      dateValue = dayjs(plan?.start_date);
    }
  }

  const plans = {
    24: {
      title: "Steady Course",
      type: "relaxed",
      months: 24,
    },
    18: {
      title: "Accelerated",
      type: "moderate",
      months: 18,
    },
    12: {
      title: "Rapid Relief",
      type: "aggressive",
      months: 12,
    },
  };

  const planData = plans[plan?.months];

  const monthlyCost = () => {
    const totalDebt = plan?.debt_amount || 0;
    const planCost = parseFloat(
      totalDebt * (plan?.service_percent / 100) +
        plan?.monthly_fees * plan?.months,
    );
    return parseFloat(planCost / plan?.months).toFixed(2);
  };

  return (
    <div className="admin-wizard-review">
      <div className="wzrd-box">
        <div className="wzrd-header">
          <h3>Review Plan Details</h3>
        </div>
        <div className="wzrd-body">
          <div className="wzrd-cart">
            <div className="cart-body">
              <div className="cb-item">
                <IconOctagon />
                <div>Relief Plan</div>
                {plan?.type && plan?.type !== "custom" ? (
                  <div>
                    <span className={`plan-circle duration-${plan.months}`} />
                    <b>{planData?.title}</b>
                  </div>
                ) : (
                  <div>
                    <span className={`plan-circle duration-custom`} />
                    <b>
                      {plan?.type === "custom" ? "Custom Plan" : "Pending..."}
                    </b>
                  </div>
                )}
              </div>
              <div className="cb-item">
                <IconCalendarMonth />
                <div>Payment start date</div>
                <div>{dateValue.utc().format("ddd, MMMM Do, YYYY")}</div>
              </div>
              <div className="cb-item">
                <IconCoin />
                <div>Payment</div>
                <div>{formatMoney(monthlyCost() || 0)} / month</div>
              </div>
              <div className="cb-item">
                <IconRepeat />
                <div>Frequency</div>
                <div>Monthly</div>
              </div>
              <div className="cb-item">
                <IconClock />
                <div>Debt Free Time Frame</div>
                <div>{plan.months} months</div>
              </div>
            </div>
            <div className="cart-info">
              <IconInfoOctagon />
              <p>{flatMoney(plan?.debt_amount)} in debt to be relieved!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Review;

import React from "react";
import { Dropdown, Flex } from "antd";
import { IconSelector } from "@tabler/icons-react";
import { logOut } from "_helpers/auth";
import { Tag } from "_styleguide";
import "./Company.scss";

// const ROLES = {
//   admin: "Admin",
//   manager: "Manager",
//   owner: "Owner",
//   rep: "Sales Rep",
//   user: "Sales Rep",
//   super: "Super Admin",
// };

function Company({ user, collapsed }) {
  const { company } = user;
  const items = [
    {
      label: (
        <div className="drop-user">
          <h5>
            {user?.name}
            <Tag type={user?.role} size="small">
              {user?.role}
            </Tag>
          </h5>
          <p>{user?.email}</p>
        </div>
      ),
      className: "owner-user-container",
    },
    { type: "divider" },
    {
      label: <span>Sign out</span>,
      onClick: logOut,
    },
  ];

  return (
    <Flex className="owner-container" vertical={true}>
      <Dropdown
        className={`company-drop ${collapsed ? "drop-collapsed" : ""}`}
        menu={{
          items,
        }}
        trigger={["click"]}
        overlayClassName="company-drop-drop"
      >
        <Flex>
          <Flex align="center" justify="center">
            {company?.name?.[0] || "P"}
          </Flex>
          {!collapsed && <h3>{company?.name}</h3>}
          <IconSelector size="18" />
        </Flex>
      </Dropdown>
    </Flex>
  );
}

export default Company;

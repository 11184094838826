import { useState } from "react";
import { useApolloClient } from "@apollo/client";
import { Button, message, Header, Modal } from "@centrate-io/barn";
import { inviteCompany } from "App/Admin/_graphql/mutations/company";
import { getErrors } from "_helpers/api";
import { FormInput, InputLabel, Flex } from "_styleguide";
import { validateInviteCompany } from "App/Admin/_helpers/validateForm";
import "./InviteCompany.scss";

function InviteCompany(props) {
  const client = useApolloClient();
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState({
    company: undefined,
    email: undefined,
  });

  // Submit question form
  const createInvitation = async () => {
    setSaving(true);
    try {
      await client.mutate({
        variables: { ...form },
        mutation: inviteCompany,
      });
      message.success(`Invite sent to ${form.email}`);
      if (props.reload) props.reload();
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error inviting company, try again");
      setSaving(false);
    }
  };

  // Update form by field and value
  const updateForm = (field, value) => {
    const editedform = { ...form };
    editedform[field] = value;
    setForm(editedform);
  };

  const validation = validateInviteCompany(form);

  return (
    <Modal
      wrapClassName="invite-company-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={500}
    >
      <Modal.Close onClick={() => setVisible(false)} />

      <Header size="5" type="display">
        Invite Partner
      </Header>

      <Modal.Body>
        <div className="invite-form">
          <Flex vertical={true}>
            <InputLabel htmlFor="company" {...validation.company}>
              Partner Name *
            </InputLabel>
            <FormInput
              id="company"
              value={form.company}
              onChange={(e) => updateForm("company", e.target.value)}
              placeholder="e.g. Google"
              {...validation.company}
            />
          </Flex>
          <Flex vertical={true}>
            <InputLabel htmlFor="email" {...validation.email}>
              Email address *
            </InputLabel>
            <FormInput
              id="email"
              value={form.email}
              onChange={(e) =>
                updateForm("email", e.target.value?.toLowerCase())
              }
              placeholder="e.g. jford@gmail.com..."
              {...validation.email}
            />
          </Flex>
        </div>
      </Modal.Body>

      <Modal.Actions>
        <Button type="secondary" onClick={() => setVisible(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          disabled={!validation.allValid}
          onClick={createInvitation}
          loading={saving}
        >
          Send Invitation
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default InviteCompany;

import { gql } from "@apollo/client";

export const inviteCompany = gql`
  mutation inviteCompany(
    $company: String!
    $email: String!
    $fromAdmin: Boolean
  ) {
    inviteCompany(company: $company, email: $email, fromAdmin: $fromAdmin)
  }
`;

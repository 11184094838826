import { useState, useEffect } from "react";
import { Button, message, Modal } from "@centrate-io/barn";
import { getErrors } from "_helpers/api";
import { IconMail, IconCheck, IconX, IconCopy } from "@tabler/icons-react";
import { FormInput, InputLabel, Flex, AlertBox } from "_styleguide";
import axios from "axios";
import "./MailgunSetup.scss";

function MailgunSetup(props) {
  const [step, setStep] = useState(props.step || "domain");
  const [domain, setDomain] = useState(props.domain || undefined);
  const [settings, setSettings] = useState(undefined);
  const [visible, setVisible] = useState(true);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);

  const createDomain = async () => {
    setSaving(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_CALLS_API_PATH}/mailgun/create-domain`,
        {
          domain: domain,
        },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
        },
      );
      setStep("verify");
      getDomain();
      message.success("Domain created, please continue setting up");
    } catch (err) {
      message.error(getErrors(err) || "Error creating domain, try again");
    }
    setSaving(false);
  };

  const getDomain = async () => {
    setLoading(true);
    try {
      const results = await axios.get(
        `${process.env.REACT_APP_CALLS_API_PATH}/mailgun/domain?domain=${domain}`,
        {
          headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
        },
      );
      setSettings(results.data);
    } catch (err) {
      message.error(getErrors(err) || "Error finding domain, try again");
    }
    setLoading(false);
  };

  const removeDomain = async () => {
    setSaving(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_CALLS_API_PATH}/mailgun/remove-domain`,
        { domain: domain },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
        },
      );
      if (props.reload) props.reload();
      message.success("Domain " + domain + " successfully removed.");
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error removing domain, try again");
    }
    setSaving(false);
  };

  const removeConfirm = () => {
    Modal.confirm({
      title: "Are you sure you want remove this domain?",
      okText: "Delete Domain",
      content: (
        <span>
          <b>WARNING</b>: Removing this domain will stop progress and require
          you to create a new domain and verify it before it can be used.
        </span>
      ),
      onOk: removeDomain,
    });
  };

  const refreshDomain = async () => {
    setSaving(true);
    try {
      const results = await axios.get(
        `${process.env.REACT_APP_CALLS_API_PATH}/mailgun/refresh?domain=${domain}`,
        {
          headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
        },
      );
      if (results.data?.domain) {
        const resultsData = { ...results.data.domain, ...results.data };
        setSettings(resultsData);
      } else {
        throw new Error();
      }
    } catch (err) {
      message.error(getErrors(err) || "Error refreshing domain, try again");
    }
    setSaving(false);
  };

  const verifyDomain = async () => {
    setSaving(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_CALLS_API_PATH}/mailgun/verify-domain`,
        { domain: domain },
        {
          headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
        },
      );
      if (props.reload) props.reload();
      message.success("Domain " + domain + " successfully setup.");
      setVisible(false);
    } catch (err) {
      message.error(getErrors(err) || "Error verifying domain, try again");
    }
    setSaving(false);
  };

  function copyToClipboard(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Text copied to clipboard");
      })
      .catch((err) => {
        message.error("Failed to copy text to clipboard:", err);
      });
  }

  useEffect(() => {
    if (props.domain) {
      getDomain();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let domainSettings = [],
    isValid = false;
  if (settings) {
    domainSettings = [
      ...settings.sending_dns_records,
      ...settings.receiving_dns_records,
    ];
    isValid = true;
    domainSettings.forEach((s) => {
      if (s.valid !== "valid") isValid = false;
    });
  }
  isValid = isValid && settings && settings.state === "active";

  return (
    <Modal
      wrapClassName="mailgun-modal"
      open={visible}
      title={null}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={props.removeModal}
      destroyOnClose={true}
      centered
      closable={true}
      maskClosable={false}
      width={step === "verify" ? 720 : 500}
    >
      <div className="pp-modal-header">
        <div>
          <IconMail />
        </div>
        <h3>
          {step === "verify"
            ? `Verify Domain via DNS${settings?.name ? `: @${settings.name}` : ""}`
            : "Setup Mailgun"}
        </h3>
      </div>

      <Modal.Body>
        {step === "domain" ? (
          <Flex vertical={true}>
            <InputLabel htmlFor="domain">Email Subdomain</InputLabel>
            <FormInput
              disabled={loading}
              id="domain"
              addonBefore="support@"
              value={domain}
              onChange={(e) => setDomain(e.target.value)}
              size="medium"
              placeholder="info.yourcompany.com"
            />
          </Flex>
        ) : null}
        {step === "verify" && settings ? (
          <Flex className="mailgun-verify" vertical={true}>
            <table className="domain-table">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th>Type</th>
                  <th>Hostname</th>
                  <th>Priority</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {domainSettings.map((s, i) => (
                  <tr key={"ds-" + i}>
                    <td>{s.valid === "valid" ? <IconCheck /> : <IconX />}</td>
                    <td>{s.record_type}</td>
                    <td>{s.name || settings.name}</td>
                    <td>{s.priority || ""}</td>
                    <td>
                      <FormInput size="tiny" value={s.value} />
                      <Button
                        type="secondary"
                        size="small"
                        className="boxed"
                        onClick={() => copyToClipboard(s.value)}
                      >
                        <IconCopy />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <AlertBox type="info">
              Please add the following entries to your DNS records for{" "}
              <b>{settings.name}</b>. This will allow you to send and receive
              emails through our platform using your custom domain{" "}
              <b>{settings.name}</b>
              <br />
              <b>Note:</b> This could take a few moments, and up to 24 hours for
              changes to show affect. Refresh to check latest status.
            </AlertBox>
          </Flex>
        ) : null}
      </Modal.Body>

      <Modal.Actions>
        {step === "domain" ? (
          <>
            <Button type="secondary" onClick={() => setVisible(false)}>
              Cancel
            </Button>
            <Button
              disabled={!domain}
              block
              loading={saving}
              type="primary"
              onClick={createDomain}
            >
              Create Domain
            </Button>
          </>
        ) : null}
        {step === "verify" ? (
          <>
            <Button type="secondary" onClick={() => setVisible(false)}>
              Close
            </Button>
            <Flex gap={12}>
              <Button type="nope" onClick={removeConfirm}>
                Delete
              </Button>
              {isValid ? (
                <Button type="primary" loading={saving} onClick={verifyDomain}>
                  Verify Domain
                </Button>
              ) : (
                <Button
                  type="secondary"
                  loading={saving}
                  onClick={refreshDomain}
                >
                  Refresh Status
                </Button>
              )}
            </Flex>
          </>
        ) : null}
      </Modal.Actions>
    </Modal>
  );
}

export default MailgunSetup;

import { Text, Button } from "@centrate-io/barn";
import {
  IconUsers,
  IconUser,
  IconBuilding,
  IconCalendar,
} from "@tabler/icons-react";
import { Tag } from "_styleguide";
import moment from "moment";

export const getColumns = (myCompanyId, masq, showPartners) => {
  return [
    {
      title: (
        <div className="column-name">
          <IconBuilding />
          Company/Partner
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 320,
      render: (name, data) => (
        <div>
          <Text className="doc-name">
            <IconBuilding />
            <b>{name}</b>
            {data?.role === "company-invite" && (
              <Tag type="success" size="small">
                Invite pending
              </Tag>
            )}
            {data?.parent ? (
              <Tag type="neutral" size="small">
                Partner
              </Tag>
            ) : null}
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>ID: {data.id}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconBuilding />
          Parent Company
        </div>
      ),
      dataIndex: "name",
      key: "name",
      width: 320,
      hidden: !showPartners,
      render: (name, data) =>
        data?.parent?.id ? (
          <div>
            <Text className="doc-name">
              <IconBuilding />
              <b>{data?.parent?.name}</b>
              {data?.parent?.role === "company-invite" && (
                <Tag type="success" size="small">
                  Invite pending
                </Tag>
              )}
            </Text>
            <Text className="helper" style={{ paddingLeft: "20px" }}>
              <i>ID: {data?.parent?.id}</i>
            </Text>
          </div>
        ) : (
          <div></div>
        ),
    },
    {
      title: (
        <div className="column-name">
          <IconUser />
          Owner
        </div>
      ),
      dataIndex: "user",
      key: "user",
      render: (user, data) => (
        <div>
          <Text className="doc-name">
            <IconUser />
            <b>{data?.user?.name || "New User"}</b>
          </Text>
          <Text className="helper" style={{ paddingLeft: "20px" }}>
            <i>{data?.user?.email}</i>
          </Text>
        </div>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconUsers />
          Users
        </div>
      ),
      dataIndex: "user_count",
      key: "user_count",
      render: (user_count) => (
        <Text>
          {user_count} user{user_count === 1 ? "" : "s"}
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Invited
        </div>
      ),
      dataIndex: "invited_date",
      key: "invited_date",
      sorter: true,
      render: (invited_date) => (
        <Text>
          {invited_date ? (
            moment(parseInt(invited_date)).format("M/D/YYYY")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
    {
      title: (
        <div className="column-name">
          <IconCalendar />
          Joined
        </div>
      ),
      dataIndex: "joined_date",
      key: "joined_date",
      sorter: true,
      render: (joined_date) => (
        <Text>
          {joined_date ? (
            moment(parseInt(joined_date)).format("M/D/YYYY")
          ) : (
            <i>n/a</i>
          )}
        </Text>
      ),
    },
    {
      title: <div className="column-name"></div>,
      dataIndex: "masquerade",
      key: "masquerade",
      width: 150,
      align: "right",
      render: (masquerade, data) =>
        data?.user?.id ? (
          <Button
            className="boxed"
            type="secondary"
            onClick={() => masq(data?.user?.id, data.id)}
            size="small"
          >
            Login as Owner
          </Button>
        ) : (
          ""
        ),
    },
  ];
};

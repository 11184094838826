import React, { useState } from "react";
import MailgunSVG from "_assets/logos/mailgun.svg";
import { IconPlugConnected } from "@tabler/icons-react";
import MailgunSetup from "../_modals/MailgunSetup/MailgunSetup";
import { Button } from "@centrate-io/barn";

const MailgunConnect = (props) => {
  const [modal, setModal] = useState(false);
  const isSetup = props.data?.settings?.domain ? true : false;

  return (
    <>
      {modal ? (
        <MailgunSetup
          reload={props.reload}
          step={isSetup ? "verify" : "domain"}
          domain={props.data?.settings?.domain}
          user={props.user}
          removeModal={() => setModal(false)}
        />
      ) : null}
      <Button className="boxed" type="primary" onClick={() => setModal(true)}>
        <IconPlugConnected /> {isSetup ? "Verify Domain" : "Setup Mailgun"}
      </Button>
    </>
  );
};

const Mailgun = {
  type: "mailgun",
  category: "email",
  company_only: true,
  contact_only: false,
  logo: MailgunSVG,
  name: "Mailgun",
  description:
    "Setup a custom email subdomain server to send AND recieve emails to/from customers in PayPath.",
  Connect: MailgunConnect,
};

export default Mailgun;

import axios from "axios";

export const getToken = async () => {
  let token = null;
  try {
    const results = await axios.get(
      `${process.env.REACT_APP_CALLS_API_PATH}/twilio/token`,
      {
        headers: { Authorization: "JWT " + localStorage.getItem("JWT") },
      },
    );
    token = results?.data?.token || null;
  } catch (err) {
    console.error(err);
  }
  return token;
};

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Tooltip, Modal, message } from "@centrate-io/barn";
import Equifax from "_assets/logos/equifax.svg";
import { FormInput, InputLabel, AlertBox } from "_styleguide";
import { FormatSSN } from "_assets/js/helpers";
import { IconInfoCircle } from "@tabler/icons-react";
import { useIntegrations } from "_contexts/useIntegrations";
import { skipCreditReport } from "_graphql/mutations/application";
import { getErrors } from "_helpers/api";
import { useApolloClient } from "@apollo/client";

function RunReport(props) {
  const client = useApolloClient();
  const { social, setSocial } = props;
  const { integrations } = useIntegrations();
  const [skipSaving, setSkipSaving] = useState(false);

  const hasCreditIntegration = integrations?.credit ? true : false;

  const skipReport = async () => {
    Modal.confirm({
      title: "Are you sure you want to skip credit report?",
      okText: "Confirm Skip",
      content:
        "Warning: This will require you to manually add all credit lines to the lead and you will not be able to run a report later.",
      onOk: async () => {
        try {
          setSkipSaving(true);
          await client.mutate({
            variables: {
              application_id: props.form?.id,
            },
            mutation: skipCreditReport,
          });
          props.reload();
          setSkipSaving(false);
        } catch (err) {
          console.log(err);
          setSkipSaving(false);
          message.error(getErrors(err) || "Error, try again");
        }
      },
    });
  };

  return (
    <div className="admin-wizard-credit">
      <div className="wzrd-box">
        <div className="wzrd-header">
          <h3>
            Run <u>Soft</u> Credit Check
          </h3>
        </div>
        <div className="wzrd-body">
          <div className="experian-button">
            Brought to you by <img src={Equifax} alt="Equifax" />
          </div>
          <div className="credit-confirmation">
            You understand that by clicking on the{" "}
            <b>“I Agree, Run Soft Credit Inquiry”</b> button immediately
            following this notice, you are providing ‘written instructions’ to
            Paypath under the Fair Credit Reporting Act authorizing Paypath to
            obtain information from your personal credit report or other
            information from Equifax. You authorize Paypath to obtain such
            information solely to conduct a pre-qualification for credit.
          </div>
          {hasCreditIntegration ? (
            <>
              <div className="form-item" style={{ marginBottom: "32px" }}>
                <InputLabel htmlFor="ssn">
                  Social Security Number{" "}
                  <Tooltip title="Only use the clients SSN if their report cannot be found with current information">
                    <span>
                      Optional <IconInfoCircle />
                    </span>
                  </Tooltip>
                </InputLabel>
                <FormInput
                  id="email"
                  value={social}
                  onChange={(e) => setSocial(FormatSSN(e.target.value, true))}
                  size="medium"
                  placeholder="111-22-3333"
                />
              </div>
              <div className="run-credit">
                <Button
                  loading={props.loadingReport}
                  type="primary"
                  size="large"
                  block
                  disabled={social?.length > 0 && social?.length < 11}
                  onClick={props.handleClick}
                >
                  I Agree, Run Soft Credit Check
                </Button>
                {props.canSkip ? (
                  <Button
                    loading={skipSaving}
                    type="secondary"
                    size="large"
                    onClick={skipReport}
                  >
                    Skip
                  </Button>
                ) : null}
              </div>
            </>
          ) : (
            <AlertBox type="info">
              You currently have no CRS integration setup for this account,
              please reach out to PayPath to get your CRS account setup so you
              can run credit reports. You can manage your integrations by{" "}
              <Link to="/settings/integrations">clicking here.</Link>
            </AlertBox>
          )}
        </div>
      </div>
    </div>
  );
}

export default RunReport;

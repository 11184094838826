import React from "react";
import DocusignSVG from "_assets/logos/docusign.svg";
import { IconPlugConnected } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";

const DocusignConnect = (props) => {
  const handleLogin = async () => {
    window.location.href = `${process.env.REACT_APP_CALLS_API_PATH}/login/docusign?user_id=${props.user?.id}&company_id=${props.user?.company?.id}`;
  };

  return (
    <Button className="boxed" type="primary" onClick={handleLogin}>
      <IconPlugConnected /> Connect Docusign
    </Button>
  );
};

const Docusign = {
  type: "docusign",
  category: "documents",
  company_only: true,
  contact_only: false,
  logo: DocusignSVG,
  name: "Docusign",
  description:
    "Login to your Docusign account to enable sending documents and completing tasks through PayPath.",
  Connect: DocusignConnect,
};

export default Docusign;

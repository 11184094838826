import { gql } from "@apollo/client";

export const getUserInvite = gql`
  query getUserInvite($user_id: ID!, $company_id: ID!) {
    getUserInvite(user_id: $user_id, company_id: $company_id) {
      company {
        id
        name
      }
      hasPassword
      name
      email
      phone
    }
  }
`;

export const updateLogin = gql`
  query {
    updateLogin
  }
`;

import React, { useState, useEffect } from "react";
import { message } from "@centrate-io/barn";
import { timeout } from "_assets/js/helpers";
import CreditSelect from "./_components/CreditSelect/CreditSelect";
import RunReport from "./_components/RunReport/RunReport";
import { getErrors } from "_helpers/api";
import axios from "axios";
import { useApolloClient } from "@apollo/client";
import { getCreditState } from "_graphql/queries/application";
import "./Credit.scss";

function Credit(props) {
  const { form } = props;
  const [loadingReport, setLoadingReport] = useState();
  const [creditData, setCreditData] = useState();
  const [stateData, setStateData] = useState();
  const [fromLoad, setFromLoad] = useState(false);
  const [social, setSocial] = useState();
  const [canSkip, setCanSkip] = useState(false);
  const client = useApolloClient();

  const handleClick = async () => {
    try {
      setLoadingReport(true);
      await timeout(500);
      await axios.post(`${process.env.REACT_APP_API_PATH}/credit-report`, {
        ...form,
        social_security_number: social,
        wizard: true,
      });
      await checkState();
      if (props.fetchReport) {
        await props.fetchReport(form?.id);
      }
    } catch (err) {
      message.error(
        getErrors(err) || "Error checking credit report, try again",
      );
      setCanSkip(true);
      setLoadingReport(false);
    }
  };

  const checkState = async () => {
    const stateResponse = await client.query({
      query: getCreditState,
      variables: {
        application_id: form?.id,
      },
    });
    const newState = stateResponse?.data?.getCreditState;
    setStateData(newState || undefined);
    if (newState?.state === "select") {
      setFromLoad(true);
      setCreditData(newState?.tradelines);
    }
    setLoadingReport(false);
  };

  useEffect(() => {
    checkState();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  console.log("stateData", stateData);

  if (stateData?.state === "credit") {
    return (
      <RunReport
        {...props}
        handleClick={handleClick}
        form={form}
        loadingReport={loadingReport}
        social={social}
        setSocial={setSocial}
        canSkip={canSkip}
        reload={checkState}
      />
    );
  } else if (stateData?.state === "select") {
    return (
      <CreditSelect
        creditSkipped={stateData?.creditSkipped}
        tradeline={creditData}
        credit={props.credit}
        actionsProps={props}
        form={form}
        next={props.next}
        fromLoad={fromLoad}
        reload={checkState}
      />
    );
  }

  return null;
}

export default Credit;

import { useState, useEffect } from "react";
import { Loading } from "_styleguide";
import { CallsClient } from "_graphql/clients/CallsClient";
import { getConversation } from "_graphql/queries/email";
import EmailOpen from "./_components/EmailOpen/EmailOpen";
import EmailCollapsed from "./_components/EmailCollapsed/EmailCollapsed";
import "./Conversation.scss";

function Conversation(props) {
  const callsClient = CallsClient;
  const [conversation, setConversation] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openConvos, setOpenConvos] = useState([]);

  // Load Text Messages
  const fetchConversation = async () => {
    const queryData = await callsClient.query({
      variables: {
        application_id: props.applicationId,
        thread_id: props.threadId,
      },
      query: getConversation,
    });
    setConversation(queryData?.data?.getConversation || []);
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
    fetchConversation();
    setOpenConvos([props.lastEmail]);
  }, [props.threadId]);

  const toggleOpen = (convoId) => {
    if (openConvos.includes(convoId)) {
      setOpenConvos(openConvos.filter((o) => o !== convoId));
    } else {
      setOpenConvos([...openConvos, convoId]);
    }
  };

  return loading ? (
    <Loading />
  ) : (
    <div className="admin-application-conversation">
      {conversation.map((c) => {
        const isOpen = openConvos.includes(c.id);
        return isOpen ? (
          <EmailOpen
            key={c.id}
            userData={props.userData}
            c={c}
            thread={props.thread}
            reloadConversation={fetchConversation}
            reloadThreads={props.reloadThreads}
          />
        ) : (
          <EmailCollapsed
            key={c.id}
            className="convo-open"
            userData={props.userData}
            c={c}
            thread={props.thread}
            toggleOpen={toggleOpen}
          />
        );
      })}
    </div>
  );
}

export default Conversation;

import React, { useState } from "react";
import PostgridSVG from "_assets/logos/postgrid.svg";
import { IconPlugConnected } from "@tabler/icons-react";
import { Button } from "@centrate-io/barn";
import PostgridSetup from "../_modals/PostgridSetup/PostgridSetup";

const PostgridConnect = (props) => {
  const [modal, setModal] = useState(false);
  return (
    <>
      {modal ? (
        <PostgridSetup
          reload={props.reload}
          user={props.user}
          removeModal={() => setModal(false)}
        />
      ) : null}
      <Button className="boxed" type="primary" onClick={() => setModal(true)}>
        <IconPlugConnected /> Connect Postgrid
      </Button>
    </>
  );
};

const Postgrid = {
  type: "postgrid",
  category: "mail",
  company_only: true,
  contact_only: false,
  logo: PostgridSVG,
  name: "Postgrid",
  description:
    "Setup your Postgrid account to enable sending physical mail and completing tasks through PayPath.",
  Connect: PostgridConnect,
};

export default Postgrid;
